import { EnumDescriptor } from "models/enum_descriptor";

export enum LockTypeEnum {
    Undefined = 0,
    Deny = 1,
    Allow = 2,
}

export const LockTypeEnumValues: Array<EnumDescriptor<LockTypeEnum>> = [
    { value: LockTypeEnum.Undefined, text: "Nicht definiert" },
    { value: LockTypeEnum.Deny, text: "verbieten" },
    { value: LockTypeEnum.Allow, text: "erlauben" },
];