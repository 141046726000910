import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { isNotEmpty } from "services/validation.service";
import { DialogComponent } from "shared/components/dialogs/dialog.component";
import { cancelDeleteValidityScope, cancelDismissChanges, confirmDeleteValidityScope, confirmDismissChanges, confirmError } from "../redux/addition.slice";
import { Grid } from "@mui/material";

export const AdditionDialogsComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.addition);

    const showDeletingValidityAreaDialog = state.actualData.additionEditor.showDeleteValidityDialog;
    const showErrorMessageDialog = state.actualData.additionEditor.showErrorMessageDialog;
    const errorMessageTitle = state.actualData.additionEditor.errorMessageTitle;
    const errorMessageDescription = state.actualData.additionEditor.errorMessageDescription;
    const showDismissValidityAreaDialog = state.actualData.additionEditor.showDismissValidityAreaDialog;

    const mainProductline = state.actualData.additionEditor.addition.scopeDeletingIndex >= 0 ? state.actualData.additionEditor.addition.additionScopes[state.actualData.additionEditor.addition.scopeDeletingIndex]?.mainProductLine : null;
    const bodyArea = state.actualData.additionEditor.addition.scopeDeletingIndex >= 0 ? state.actualData.additionEditor.addition.additionScopes[state.actualData.additionEditor.addition.scopeDeletingIndex]?.bodyArea : null;
    const deleteMessage = mainProductline != null && isNotEmpty(mainProductline.name) && bodyArea != null && isNotEmpty(bodyArea.name) ? "Möchten Sie den Gültigkeitsbereich " + mainProductline.name + ", " + bodyArea.name + " löschen?" :
        "Möchten Sie den Gültigkeitsbereich löschen?";

    return (<>
            {
                showDeletingValidityAreaDialog &&
                <DialogComponent
                    title={"Gültigkeitsbereich löschen?"}
                    isOpen={true}
                    options={[
                        {
                            title: "Abbrechen", handleOption: () => {
                                dispatch(cancelDeleteValidityScope())
                            }
                        },
                        {
                            title: "Löschen", handleOption: () => {
                                dispatch(confirmDeleteValidityScope())
                            }
                        }
                    ]}
                    onClose={() => {
                    }}>
                    <Grid container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}>
                        <Grid item>{deleteMessage}</Grid>
                    </Grid>
                </DialogComponent>
            }

            {
                showErrorMessageDialog &&
                <DialogComponent
                    title={errorMessageTitle}
                    isOpen={true}
                    options={[
                        { title: "Okay", handleOption: () => dispatch(confirmError()) }
                    ]}
                    onClose={() => dispatch(confirmError())}>
                    <div>{errorMessageDescription}</div>
                </DialogComponent>
            }

            {
                showDismissValidityAreaDialog &&
                <DialogComponent
                    title={"Änderungen verwerfen?"}
                    isOpen={true}
                    options={[
                        {
                            title: "Ja", handleOption: () => dispatch(confirmDismissChanges())
                        },
                        {
                            title: "Nein", handleOption: () => dispatch(cancelDismissChanges())
                        }
                    ]}
                    onClose={() => dispatch(cancelDismissChanges())}>
                </DialogComponent>
            }
    </>)
};