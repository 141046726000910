import { doesExist } from "services/validation.service"
import { ProductionInstructionCreateState } from "./production_instruction_create.model"

export const updateCanSave = (state: ProductionInstructionCreateState) => {
    state.command.saveProductionInstruction.canExecute =
        doesExist(state.actualData.productionInstructionEditor.isFreeTextEnabled)
        && doesExist(state.actualData.productionInstructionEditor.isDocumentsEnabled)
        && doesExist(state.actualData.productionInstructionEditor.isAutomaticProductionEnabled)
        && doesExist(state.actualData.productionInstructionEditor.calingaKey)
        && doesExist(state.actualData.productionInstructionEditor.symbol);
}