import { Grid } from '@mui/material';
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style'
import { ValidityItemComponent } from './addition_validity_item.component';
import { EditorDescriptionComponent } from 'shared/components/editors/editor_description/editor_description.style';
import { RadioBoxAdvancedComponent } from "shared/shared";
import { ListEditorComponent } from 'shared/components/editors/list_editor/list_editor.component';
import { EditorConfigurationComponent } from 'shared/components/editors/list_editor/editor_configuration.component';
import { isValidValidityScopeByIndex } from 'additions/common/helpers/validityScope';
import { doesExist } from 'services/validation.service';
import { ListEditorMode } from 'shared/components/editors/list_editor/enums/EditorMode';
import { EditorSummaryDefinition } from 'shared/components/editors/list_editor/models/EditorSummaryDefinition';
import { IAdditionInitial, IAdditionEditor, IAdditionEditorAction, IAdditionViewModelScope } from 'models/additions/addition_editor.model';

interface ValidityComponentProps {
    additionInitial: IAdditionInitial,
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction,
}

export const ValidityComponent: React.FC<ValidityComponentProps> = (
    {
        additionInitial,
        additionEditor,
        additionEditorActions,
    }) => {

    const editorSummaryDefinitions: EditorSummaryDefinition[] = [{
        heading: "Hauptproduktlinie",
        flex: 1,
        displayValue: (value: IAdditionViewModelScope) => (value.mainProductLine.name)
    },
    {
        heading: "Körperregion",
        flex: 1,
        displayValue: (value: IAdditionViewModelScope) => (value.bodyArea.name)
    }];

    const renderContent = () => {
        return (
            <Grid item>
                <ValidityItemComponent
                    additionInital={additionInitial}
                    additionEditor={additionEditor}
                    additionEditorActions={additionEditorActions} />
            </Grid>
        )
    };

    const isEditableScopeValid = () => {
        return additionEditor.addition.editableScope.bodyArea && additionEditor.addition.editableScope.mainProductLine
    }

    const renderNewConfiguration = () => {
        return <EditorConfigurationComponent
            renderContent={renderContent}
            disabledTakeConfiguration={!isEditableScopeValid()}
            onTakeConfiguration={additionEditorActions.applyEditableScope}
            onCancelConfiguration={additionEditorActions.cancelEditableScope}
        />
    }

    const isValidScope = (index: number) => {
        return isValidValidityScopeByIndex(index, additionEditor, additionInitial);
    }

    const getEditorMode = () => {
        if (doesExist(additionEditor.addition.editableScope)) {
            return additionEditor.addition.editableScopeIndex === -1 ? ListEditorMode.New : ListEditorMode.Edit;
        } else {
            return ListEditorMode.None;
        }
    }

    return (<Grid container
        spacing={3}
        direction="column"
        justifyContent="space-between">
        <Grid item md={6}>
            <EditorSectionHeaderComponent>Gültigkeitsbereiche</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>Ein Gültigkeitsbereich definiert unter welchen Bedingungen der aktuelle Zusatz angezeigt werden kann. Hier ist es möglich für jede Hauptproduktlinie/Körperregion verschiedene Kombinationen aus Artikelarten und Qualitäten zu erlauben bzw verbieten.</EditorDescriptionComponent>
        </Grid>
        <Grid item md={6}>
            <EditorSectionHeaderComponent>Erlauben</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>wird die Artikelart oder Qualität in der Produktkonfiguration ausgewählt, dann sind sie für die ausgewählte Kombination aus Hauptproduktlinie und Körperregion gültig</EditorDescriptionComponent>
        </Grid>
        <Grid item md={6}>
            <EditorSectionHeaderComponent>Verbieten</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>wird die Artikelart oder Qualität in der Produktkonfiguration ausgewählt, dann sind sie für die ausgewählte Kombination aus Hauptproduktlinie und Körperregion gesperrt</EditorDescriptionComponent>
        </Grid>
        <Grid item md={6}>
            <EditorDescriptionComponent>Alle Felder mit * sind Pflichtangaben.</EditorDescriptionComponent>
        </Grid>
        <Grid item md={9}>
            <RadioBoxAdvancedComponent name="Anfertigung"
                description="Auswahl gilt für alle Gültigkeitsbereiche"
                data={additionInitial.productionTypes}
                displayedProperties={["text"]}
                onSelect={additionEditorActions.updateProductionType}
                isRequired={false}
                selectedItem={additionEditor.addition.productionType}
            />
        </Grid>
        <Grid item md={12}>
            <ListEditorComponent
                addButtonText="Neuen Gültigkeitsbereich hinzufügen"
                editorSummaryDefinitions={editorSummaryDefinitions}
                items={additionEditor.addition.additionScopes}
                onEditConfiguration={additionEditorActions.editScopeConfiguration}
                onDeleteConfiguration={additionEditorActions.deleteValidityScope}
                onNewConfiguration={additionEditorActions.addValidityScope}
                isValid={isValidScope}
                renderConfiguration={renderNewConfiguration}
                editorMode={getEditorMode()}
                editableConfigurationIndex={additionEditor.addition.editableScopeIndex} />
        </Grid>
    </Grid>
    );
};