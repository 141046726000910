import { createAsyncThunk } from '@reduxjs/toolkit';
import AdditionCategoryService from 'services/addition_category.service';
import { handleResponseErrors, errorMessages } from '../../../shared/networking/error_handling';
import { RootState } from 'app/redux_store';

export const getAdditionCategories = createAsyncThunk(
    'additioncategory/overview/getAdditionCategories',
    async (_) => {
        const response = await AdditionCategoryService.getAdditionCategoryOverview();
        handleResponseErrors(response, "Zusatzekategorien");
        return response;
    }
)

export const confirmDeleteAdditionCategory = createAsyncThunk(
    'additioncategory/overview/confirmDeleteAdditionCategory',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const activeAdditionId = state.addition_category_overview.actualData.activeAdditionCategory.id

        const deleteResponse = await AdditionCategoryService.deleteAdditionCategory(activeAdditionId);
        if(deleteResponse.isConflict) {
            handleResponseErrors(deleteResponse, "Zusatzkategorie", errorMessages.dataAllreadyUsedExists("Zusatzkategorie"));
        } else {
            handleResponseErrors(deleteResponse, "Zusatzkategorie");
        }

        const additionsResponse = await AdditionCategoryService.getAdditionCategoryOverview();
        handleResponseErrors(additionsResponse, "Zusatzekategorien");
        return additionsResponse;
    }
)

export const createNewAdditionCategory = createAsyncThunk(
    'additioncategory/overview/createNewAdditionCategory',
    async (_) => { }
)
