import { MasspointTypeEnum } from "models/masspoints/enums/masspoint_type.enum";
import { AdditionCompressionClassEnum } from "./addition_compressionclass.enum";
import { AdditionLockTypeEnum } from "./addition_lockType.enum";
import { ProductionTypeEnum } from "./productiontype.enum";
import { LockTypeEnum } from "shared/components/selectionComponent/models/locktype.enum";

export default interface IAdditionCreate {
    id?: number;
    option: string;
    code: string;
    description: string;
    quantityCode?: number;
    quantityText: string;
    side: number;
    createOrderPosition: boolean;
    calculateOrderPosition: boolean;
    orderManagement: number;
    reOrder: boolean;
    automaticProduction: boolean;
    isFullyKnitted: boolean;
    isPositioned: boolean;
    isRecipeValid: boolean;
    isGusset: boolean;
    isMultiply: boolean;
    compressionClass: AdditionCompressionClassEnum;
    productionType: ProductionTypeEnum;
    countryLocked: boolean,
    countryLockType: AdditionLockTypeEnum,
    footTypeLocked: boolean,
    footTypeLockType: LockTypeEnum,
    printArea: number;
    categoryId: number;
    placeholder: IPlaceholderSave;
    additionScopes: IAdditionScopeSave[];
    additionExcludes: IAdditionExcludeSave[];
    additionGussets: IAdditionGussetSave[];
    additionCountries: IAdditionCountrySave[];
    additionAttributeCategories: IAddtionAttributeCategorySave[];
    additionFootTypes: IAdditionFootTypeSave[];
    assignedPositionCategories: IAssignedPositionCategorySave[];
    positionCode: string;
    isCharacteristic: boolean;
    characteristicCategoryId?: number;
}

export interface IAddtionAttributeCategorySave {
    availableAdditionAttributeCategoryId: number;
    type: IAdditionCategoryTypeEnum;
    additionAttributes: IAdditionAttributeRequestSave[];
}

export enum IAdditionCategoryTypeEnum {
    Undefined,
    Whitelist,
    Blacklist
}

export interface IAdditionAttributeRequestSave {
    availableAdditionAttributeId: number;
}

export interface IAdditionCountrySave {
    id: number;
}

export interface IAdditionFootTypeSave {
    id: number;
}

export interface IAssignedPositionCategorySave {
    positionCategoryId: number;
}

export interface IAdditionExcludeSave {
    additionId: number;
}

export interface IAdditionGussetSave {
    priority: number;
    additionGussetArticleTypes: IAdditionGussetArticleTypeSave[];
    additionGussetQualities: IAdditionGussetQualitySave[];
    additionGussetMassPoints: IAdditionGussetMassPointSave[];
}

export interface IAdditionGussetArticleTypeSave {
    articleTypeId: number;
}

export interface IAdditionGussetQualitySave {
    qualityId: number;
}

export interface IAdditionGussetMassPointSave {
    bodyAreaId: number;
    maximalValue: number;
    minimalValue: number;
    name: string;
    type: MasspointTypeEnum;
}

export interface IPlaceholderSave {
    placeholderTemplate: number;
    defaultConfig: IPlaceholderConfigSave;
    qualityConfigs: IPlaceholderQualityConfigSave[];
    twoRangePlaceholderIsSideSpecific: boolean;
    isInteger: boolean;
}

export interface IPlaceholderConfigSave{
    textInputPlaceholder: ITextInputPlaceholderSave;
    rangeInputPlaceholder: IRangeInputPlaceholderSave;
    twoRangeInputPlaceholder: ITwoRangeInputPlaceholderSave;
}

export interface IPlaceholderQualityConfigSave{
    qualityId: number;
    config: IPlaceholderConfigSave;
}

export interface ITextInputPlaceholderSave {
    textInputMaxLength: number;
}

export interface IRangeInputPlaceholderSave {
    minimalValue: number;
    maximalValue: number;
}

export interface ITwoRangeInputPlaceholderSave {
    firstMinimalValue: number;
    firstMaximalValue: number;
    secondMinimalValue: number;
    secondMaximalValue: number;
}

export interface IAdditionScopeSave {
    mainProductLineId: number;
    bodyAreaId: number;
    articleTypeValidityType: LockTypeEnum;
    qualityValidityType: LockTypeEnum;
    articleTypes: IAdditionArticleTypeScopeSave[];
    qualities: IAdditionQualityScopeSave[];
}

export interface IAdditionArticleTypeScopeSave {
    articleTypeId: number;
}

export interface IAdditionQualityScopeSave {
    qualityId: number;
}