import { Checkbox, FormControlLabel } from "@mui/material";
import { IMassDefinitionMasspoint } from "models/massdefinitions/massdefinition";
import { useEffect, useRef } from "react";
import { MassmaskInfoComponent } from "./components/massmask_info.component";
import { SimulationFilterComponent } from "./components/simulation_filter.component";
import { LoadingIndicatorComponent } from "shared/components/loading_indicator/loading_indicator.component";
import { PrimaryButtonComponent } from "shared/shared";
import { EvaluationResultEnum } from '../../models/simulator/product_configuration_evaluation';
import { useAppDispatch, useAppSelector } from "app/hook";
import {
    initializeMassMaskSimulation,
    createOfferWithoutMasspoint,
    createCustomDesignOffer,
    createCustomDesignOrder,
    massMaskValidation,
    additionsValidation,
    productionInstructionsValidation,
    updateCustomDesign,
    initializeProductConfigurationSimulation
} from "./redux/simulator_simulation.thunks";
import { useSnackbar } from "notistack";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { AppDispatch } from '../../app/redux_store';
import { SimulationTabComponent } from './components/simulation_tab.component'
import {
    resetState,
    getAdditionDefinitionErrorCompleted,
    resetToLoadingState,
    selectIncludeUnpublished,
    initializeSideDefinition,
    updateShowEvaluationResultInMassmask,
    setAdditionsChanged,
    setProductInstructionsChanged,
    additionsEvaluationFailed,
    evaluateAdditionErrorCompleted,
    additionsEvaluating,
    additionsEvaluated,
    additionsLoaded,
    additionsLoading,
    additionsLoadingFailed,
    productionInstructionsEvaluated,
    productionInstructionsEvaluating,
    productionInstructionsEvaluationFailed,
    productionInstructionsLoaded,
    productionInstructionsLoading,
    productionInstructionsLoadingFailed,
    massMaskEvaluated,
    massMaskEvaluating,
    massMaskEvaluationFailed,
    massMaskLoaded,
    massMaskLoading,
    massMaskLoadingFailed,
    productConfigurationStarted,
    createCustomDesignOfferErrorCompleted,
    createCustomDesignOrderErrorCompleted,
    massMaskReloadComplete,
    updateSimulatorState,
    updateCustomDesignErrorCompleted,
    setMassMaskChanged
} from '../simulator_simulation/redux/simulator_simulation.slice';
import { LoadingOverlayContainer, LoadingOverlayComponent } from '../../shared/components/loading_overlay/loading_overlay.component';
import { MassmaskWrapperComponent } from './components/massmask_wrapper.component';
import { EvaluationState } from "./redux/simulator_simulation.model";
import { useParams } from "react-router-dom";
import { ProcessTypeEnum } from "models/simulator/process_type.enum";
import { OfferTypeEnum } from "models/simulator/offer_type.enum";
import { SimulationTypeEnum } from "models/simulator/simulation_type.enum";
import { doesExist } from "services/validation.service";
import { AdditionsEvaluatedEvent, ComponentDataEvent, FailedDataEvent, MassMaskEvaluatedEvent } from "@medi-mtm/components";

export interface IInteractiveMasspoint extends IMassDefinitionMasspoint {
    isHighlighted: boolean;
    selector: string;
    evaluationResult?: EvaluationResultEnum;
}

export const SimulatorSimulationComponent = () => {

    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const state = useAppSelector((state) => state.simulation);
    const massmaskRef = useRef(null);
    const additionWrapperRef = useRef(null);
    const {
        mainProductLineErpId,
        bodyAreaErpId,
        articleTypeErpId,
        productConfiguration,
        configurationRun
    } = useParams();

    useEffect(() => {
        switch (state.simulation.evaluationState) {
            case EvaluationState.evaluatedMassMask:
                if (!isQuotationWithoutMassMask()) {
                    dispatch(massMaskValidation(state.simulation.productConfigurationId));
                } else {
                    dispatch(updateSimulatorState(EvaluationState.loadAdditions));
                }
                break;
            case EvaluationState.reloadMassMask:
                if (state.query.validateMassMask.status === "success") {
                    enqueueSnackbar("Die Maßmasken Komponentenversion ist veraltet. Die Komponente wird mit der neuesten Version neu geladen", { variant: "warning" });
                    dispatch(massMaskReloadComplete());
                }
                massmaskRef.current.initializeMassMask();
                break;
            case EvaluationState.validatedMassMask:
                dispatch(updateSimulatorState(EvaluationState.loadAdditions));
                break;
            case EvaluationState.evaluatedCustomDesign:
                dispatch(updateSimulatorState(EvaluationState.loadAdditions));
                break;
            case EvaluationState.loadAdditions:
                additionWrapperRef.current.initializeAdditions();
                break;
            case EvaluationState.evaluatedAdditions:
                dispatch(additionsValidation(state.simulation.productConfigurationId));
                break;
            case EvaluationState.reloadAdditions:
                if (state.query.validateAdditions.status === "success") {
                    enqueueSnackbar("Die Zusatz Komponentenversion ist veraltet. Die Komponente wird mit der neuesten Version neu geladen", { variant: "warning" });
                }
                additionWrapperRef.current.initializeAdditions();
                break;
            case EvaluationState.validatedAdditions:
                dispatch(updateSimulatorState(EvaluationState.loadProductionInstructions));
                break;
            case EvaluationState.loadProductionInstructions:
                additionWrapperRef.current.initializeProductionInstructions();
                break;
            case EvaluationState.evaluatedProductionInstructions:
                dispatch(productionInstructionsValidation(state.simulation.productConfigurationId));
                break;
            case EvaluationState.reloadProductionInstructions:
                if (state.query.validateProductionInstructions.status === "success") {
                    enqueueSnackbar("Die Produktionsanweisung Komponentenversion ist veraltet. Die Komponente wird mit der neuesten Version neu geladen", { variant: "warning" });
                }
                additionWrapperRef.current.initializeProductionInstructions();
                break;
            case EvaluationState.restoring:
                if (state.storedConfigurationData.content.isCustomDesign && !doesExist(state.storedConfigurationData.content.additionInput)) {

                    dispatch(updateSimulatorState(EvaluationState.loadAdditions));
                }
                if (state.storedConfigurationData.content.isCustomDesign && doesExist(state.storedConfigurationData.content.additionInput)) {
                    dispatch(updateSimulatorState(EvaluationState.restoreAdditions));
                }
                if (!state.storedConfigurationData.content.isCustomDesign && doesExist(state.storedConfigurationData.content.massmaskInput)) {
                    dispatch(updateSimulatorState(EvaluationState.restoreMassMask));
                }
                break;
            case EvaluationState.restoreMassMask:
                massmaskRef.current.initializeMassMask();
                break;
            case EvaluationState.restoredMassMask:
                if (doesExist(state.storedConfigurationData.content.additionInput)) {
                    dispatch(updateSimulatorState(EvaluationState.restoreAdditions));
                } else {
                    dispatch(updateSimulatorState(EvaluationState.loadAdditions));
                }
                break;
            case EvaluationState.restoreAdditions:
                additionWrapperRef.current.initializeAdditions();
                break;
            case EvaluationState.restoredAdditions:
                if (doesExist(state.storedConfigurationData.content.productionInstructionInput)) {
                    dispatch(updateSimulatorState(EvaluationState.restoreProductionInstructions));
                } else {
                    dispatch(updateSimulatorState(EvaluationState.loadProductionInstructions));
                }
                break;
            case EvaluationState.restoreProductionInstructions:
                additionWrapperRef.current.initializeProductionInstructions();
                break;
            case EvaluationState.restoredProductionInstructions:
                dispatch(updateSimulatorState(EvaluationState.validatedProductionInstruction));
                break;
        }
    }, [state.simulation.evaluationState])

    useEffect(() => {
        const initSideDefinition = {
            mainProductLineErpId: mainProductLineErpId,
            bodyAreaErpId: bodyAreaErpId,
            articleTypeErpId: articleTypeErpId,
        };
        dispatch(initializeSideDefinition(initSideDefinition));
        if (doesExist(productConfiguration)) {
            dispatch(initializeProductConfigurationSimulation({
                productConfigurationId: productConfiguration,
                productConfigurationRunId: doesExist(configurationRun) ? parseFloat(configurationRun) : null
            }));
        } else {
            dispatch(initializeMassMaskSimulation());
        }

        return () => {
            dispatch(resetState())
        };
    }, [dispatch, mainProductLineErpId, bodyAreaErpId, articleTypeErpId, productConfiguration, configurationRun]);

    if (state.query.getMassMask.status === "error") {
        enqueueSnackbar(state.query.getMassMask.message, { variant: "error" });
        dispatch(resetToLoadingState());
        dispatch(initializeMassMaskSimulation());
    }

    if (state.command.createOfferWithoutMasspoint.status === "error")  {
        enqueueSnackbar(state.command.createOfferWithoutMasspoint.message, { variant: "error" });
    }

    if (state.query.getArticleAttributeCategories.status === "error") {
        enqueueSnackbar(state.query.getArticleAttributeCategories.message, { variant: "error" });
        dispatch(resetToLoadingState());
        dispatch(initializeMassMaskSimulation());
    }

    if (state.query.getFootoptions.status === "error") {
        enqueueSnackbar(state.query.getFootoptions.message, { variant: "error" });
        dispatch(resetToLoadingState());
        dispatch(initializeMassMaskSimulation());
    }

    if (state.query.checkOneleg.status === "error") {
        enqueueSnackbar(state.query.checkOneleg.message, { variant: "error" });
        dispatch(resetToLoadingState());
        dispatch(initializeMassMaskSimulation());
    }

    if (state.query.fetchBaseData.status === "error") {
        enqueueSnackbar(state.query.fetchBaseData.message, { variant: "error" });
        dispatch(resetToLoadingState());
        dispatch(initializeMassMaskSimulation());
    }

    if (state.query.getQualities.status === "error") {
        enqueueSnackbar(state.query.getQualities.message, { variant: "error" });
        dispatch(resetToLoadingState());
        dispatch(initializeMassMaskSimulation());
    }

    if (state.query.validateMassMask.status === "error") {
        enqueueSnackbar(state.query.getMassMask.message, { variant: "warning" });
    }

    if (state.query.getAdditions.status === "error") {
        enqueueSnackbar(state.query.getAdditions.message, { variant: "warning" });
        dispatch(getAdditionDefinitionErrorCompleted());
    }

    if (state.query.initData.status === "pending" || state.query.initData.status === "idle") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.evaluateAddition.status === "error") {
        dispatch(evaluateAdditionErrorCompleted());
    }

    if (state.command.createCustomDesignOffer.status === "error") {
        enqueueSnackbar(state.command.createCustomDesignOffer.message, { variant: "error" });
        dispatch(createCustomDesignOfferErrorCompleted());
    }

    if (state.command.createCustomDesignOrder.status === "error") {
        enqueueSnackbar(state.command.createCustomDesignOrder.message, { variant: "error" });
        dispatch(createCustomDesignOrderErrorCompleted());
    }

    if (state.command.updateCustomDesign.status === "error") {
        enqueueSnackbar(state.command.updateCustomDesign.message, { variant: "error" });
        dispatch(createCustomDesignOfferErrorCompleted());
    }

    if (state.command.updateCustomDesign.status === "error") {
        enqueueSnackbar(state.command.updateCustomDesign.message, { variant: "error" });
        dispatch(updateCustomDesignErrorCompleted());
    }

    const showLoadingOverlay = () => {
        return !(
            state.simulation.evaluationState === EvaluationState.presetSimulator
            || state.simulation.evaluationState === EvaluationState.loadMassMask
            || state.simulation.evaluationState === EvaluationState.loadedMassMask
            || state.simulation.evaluationState === EvaluationState.loadedAdditions
            || state.simulation.evaluationState === EvaluationState.loadedProductionInstructions
            || state.simulation.evaluationState === EvaluationState.evaluateCustomDesign
            || state.simulation.evaluationState === EvaluationState.validatedProductionInstruction
        );
    }

    const massMaskLoadFailed = (detail: FailedDataEvent) => {
        enqueueSnackbar(detail.error.message, { variant: "error" });
        const errorMessage = detail.error.message + (detail.error.messageParameter && detail.error.messageParameter.masspoints ? ` (${detail.error.messageParameter?.masspoints})` : "");
        dispatch(massMaskLoadingFailed(errorMessage));
    };

    const massMaskEvaluationFinished = (details: MassMaskEvaluatedEvent) => {
        dispatch(massMaskEvaluated(details));
    };

    const massMaskEvaluateFailed = (detail: FailedDataEvent) => {
        enqueueSnackbar(detail.error.message, { variant: "error" });
        dispatch(massMaskEvaluationFailed());
    };

    const additionLoadFailed = (detail: FailedDataEvent) => {
        enqueueSnackbar(detail.error.message, { variant: "error" });
        dispatch(additionsLoadingFailed(detail.error));
    }

    const additionsEvaluatedFinished = (event: AdditionsEvaluatedEvent) => {
        dispatch(additionsEvaluated(event));
    }

    const additionEvaluateFailed = (detail: FailedDataEvent) => {
        enqueueSnackbar(detail.error.message, { variant: "error" });
        dispatch(additionsEvaluationFailed());
    }

    const productInstructionLoadFailed = (detail: FailedDataEvent) => {
        enqueueSnackbar(detail.error.message, { variant: "error" });
        dispatch(productionInstructionsLoadingFailed());
    }

    const productInstructionEvaluateFailed = (detail: FailedDataEvent) => {
        enqueueSnackbar(detail.error.message, { variant: "error" });
        dispatch(productionInstructionsEvaluationFailed());
    }

    const isQuotationWithoutMassMask = (): boolean => {
        if (state.simulation.simulationType === SimulationTypeEnum.Mass && state.simulation.processType === ProcessTypeEnum.Offer && state.simulation.offerType === OfferTypeEnum.OfferWithoutMassmask) {
            return true;
        }

        return false;
    }

    const isCustomDesign = (): boolean => {
        if (state.simulation.simulationType === SimulationTypeEnum.CustomDesign) {
            return true;
        }

        return false;
    }

    const startSimulation = () => {
        dispatch(productConfigurationStarted());
        if (!isCustomDesign()) {
            if (isQuotationWithoutMassMask()) {
                dispatch(createOfferWithoutMasspoint({ articleTypeErpId, bodyAreaErpId, mainProductLineErpId }));
            }
            else {
                massmaskRef?.current.initializeMassMask();
            }
        }
    }

    const startCustomDesign = () => {
        dispatch(productConfigurationStarted());
        if (isCustomDesign()) {
            if (state.simulation.productConfigurationId === "00000000-0000-0000-0000-000000000000") {
                if (state.simulation.processType === ProcessTypeEnum.Offer) {
                    dispatch(createCustomDesignOffer({ articleTypeErpId, bodyAreaErpId, mainProductLineErpId }));
                }
                else {
                    dispatch(createCustomDesignOrder({ articleTypeErpId, bodyAreaErpId, mainProductLineErpId }));
                }
            } else {
                dispatch(updateCustomDesign({ articleTypeErpId, bodyAreaErpId, mainProductLineErpId }));
            }
        }
    }

    const onProductionInstructionComponentState = (eventData: ComponentDataEvent) => {
        if (eventData.message) {
            enqueueSnackbar(eventData.message, { variant: "warning" });
        }
        dispatch(setProductInstructionsChanged(eventData));
    }

    const onAdditionsComponentState = (eventData: ComponentDataEvent) => {
        if (eventData.message) {
            enqueueSnackbar(eventData.message, { variant: "warning" });
        }
        dispatch(setAdditionsChanged(eventData));
    }

    const onMassMaskComponentState = (eventData: ComponentDataEvent) => {
        if (eventData.message) {
            enqueueSnackbar(eventData.message, { variant: "warning" });
        }
        dispatch(setMassMaskChanged(eventData));
    }

    const isMass = state.simulation.simulationType === SimulationTypeEnum.Mass;

    return (
        <>
            <LoadingOverlayContainer>
                {showLoadingOverlay() ? <LoadingOverlayComponent /> : <></>}
                <MassmaskInfoComponent />
                <SimulationFilterComponent
                    startSimulation={() => startSimulation()}
                    startCustomDesign={() => startCustomDesign()}
                />
                {
                    state.simulation.simulationType === SimulationTypeEnum.Mass
                    && !isQuotationWithoutMassMask()
                    && state.simulation.evaluationState >= EvaluationState.loadMassMask
                    && <MassmaskWrapperComponent
                        ref={massmaskRef}
                        mainProductLineErpId={mainProductLineErpId}
                        bodyAreaErpId={bodyAreaErpId}
                        articleTypeErpId={articleTypeErpId}
                        qualityErpId={state.simulation.selectedQuality!.erpId}
                        footOptionErpId={state.simulation.selectedFootoption!.externalIdentifier}
                        selectedSide={state.simulation.selectedSide!}
                        productConfigurationId={state.simulation.productConfigurationId}
                        productConfigurationRunId={state.simulation.configurationRunId}
                        useUnpublished={state.simulation.includeUnpublished}
                        processType={state.simulation.processType}
                        diagnoseModeEnabled={state.simulation.diagnoseModeEnabled}
                        updateShowEvaluationResultInMassmask={state.simulation.showEvaluationResultInMassmask}
                        updateMassMaskEvaluating={() => dispatch(massMaskEvaluating())}
                        updateMassMaskEvaluationFailed={(details) => massMaskEvaluateFailed(details)}
                        updateMassMaskEvaluated={(details) => massMaskEvaluationFinished(details)}
                        updateMassMaskLoading={() => dispatch(massMaskLoading())}
                        updateMassMaskLoadingFailed={(details) => massMaskLoadFailed(details)}
                        updateMassMaskLoaded={(details) => dispatch(massMaskLoaded(details))}
                        onMassMaskComponentUpdated={(value) => onMassMaskComponentState(value)}
                        showMassMask={
                            state.simulation.simulationType === SimulationTypeEnum.Mass
                            && state.simulation.evaluationState >= EvaluationState.loadedMassMask
                            && state.simulation.simulationType === SimulationTypeEnum.Mass
                            && !isQuotationWithoutMassMask()
                        }
                        showSnackbar={() => { enqueueSnackbar("Komponente wurde modifiziert", { variant: "info" }) }}
                    />
                }
                {
                    state.simulation.massMaskLoadingFailedErrorMessage &&
                    <div style={{ marginTop: 4 }}>
                        {state.simulation.massMaskLoadingFailedErrorMessage}
                    </div>
                }
                {
                    !state.simulation.productConfigurationIsLocked &&
                    <div style={{ marginTop: 2, display: "grid", justifyContent: "flex-end" }}>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="unpublished-checks-checkbox"
                                        checked={state.simulation.includeUnpublished}
                                        onChange={() => dispatch(selectIncludeUnpublished(
                                            {
                                                includeUnpublished: !state.simulation.includeUnpublished
                                            }
                                        ))}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="nicht veröffentliche Konfiguration einbeziehen"
                            />
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            {
                                state.simulation.evaluationState === EvaluationState.loadedMassMask ?
                                    <PrimaryButtonComponent
                                        disabled={!state.command.evaluateMassMask.canExecute}
                                        onClick={() => { massmaskRef.current.evaluate(); }} >
                                        <PlayArrowIcon />
                                        {" "}
                                        Simulation ohne Zusätze starten
                                    </PrimaryButtonComponent>
                                    : <></>
                            }
                            {
                                state.simulation.evaluationState === EvaluationState.loadedAdditions ?
                                    <PrimaryButtonComponent
                                        disabled={!state.command.evaluateAddition.canExecute}
                                        onClick={() => { additionWrapperRef.current.evaluateAdditions(); }} >
                                        <PlayArrowIcon />
                                        {" "}
                                        Simulation mit Zusätzen starten
                                    </PrimaryButtonComponent>
                                    : <></>
                            }
                            {
                                state.simulation.evaluationState === EvaluationState.loadedProductionInstructions ?
                                    <PrimaryButtonComponent
                                        disabled={!state.command.evaluateProductionInstruction.canExecute}
                                        onClick={() => { additionWrapperRef.current.evaluateProductionInstructions(); }} >
                                        <PlayArrowIcon />
                                        {" "}
                                        Simulation mit Produktionsanweisungen starten
                                    </PrimaryButtonComponent>
                                    : <></>
                            }

                        </div>
                    </div>
                }
                {
                    (state.simulation.evaluationState > EvaluationState.loadMassMask && isMass)
                        || (state.simulation.evaluationState > EvaluationState.evaluatedCustomDesign)
                        ? <SimulationTabComponent
                            ref={additionWrapperRef}
                            isMass={isMass}
                            productConfigurationId={state.simulation.productConfigurationId}
                            configurationRunId={state.simulation.configurationRunId}
                            evaluations={state.simulation.evaluations}
                            evaluationState={state.simulation.evaluationState}
                            footOptionExternalIdentifier={state.simulation.selectedFootoption!.externalIdentifier}
                            updateShowEvaluationResultInMassmask={(value) => dispatch(updateShowEvaluationResultInMassmask(value))}
                            showEvaluationResultInMassmask={state.simulation.showEvaluationResultInMassmask}
                            mainProductLineErpId={`${state.baseData.availableMainProductLines.find(x => x.erpId === mainProductLineErpId).erpId}`}
                            bodyAreaErpId={`${state.baseData.availableBodyAreas.find(x => x.erpId === bodyAreaErpId).erpId}`}
                            articleTypeErpId={`${state.baseData.availableArticleTypes.find(x => x.erpId === articleTypeErpId).erpId}`}
                            attributeCategoryDefinitions={state.simulation.additionCategoryAttributes}
                            qualityId={state.simulation.selectedQuality.erpId}
                            countryId={`${state.simulation.selectedCountry.countryCode}`}
                            useUnpublishedChecks={state.simulation.includeUnpublished}
                            productConfigurationIsLocked={state.simulation.productConfigurationIsLocked}
                            updateAdditionsEvaluating={() => dispatch(additionsEvaluating())}
                            updateAdditionsEvaluationFailed={(details) => additionEvaluateFailed(details)}
                            updateAdditionsEvaluated={(event) => additionsEvaluatedFinished(event)}
                            updateAdditionsLoading={() => dispatch(additionsLoading())}
                            updateAdditionsLoadingFailed={(details) => additionLoadFailed(details)}
                            updateAdditionsLoaded={(event) => dispatch(additionsLoaded(event))}
                            updateProductionInstructionsEvaluating={() => dispatch(productionInstructionsEvaluating())}
                            updateProductionInstructionsEvaluationFailed={(details) => productInstructionEvaluateFailed(details)}
                            updateProductionInstructionsEvaluated={(event) => dispatch(productionInstructionsEvaluated(event))}
                            updateProductionInstructionsLoading={() => dispatch(productionInstructionsLoading())}
                            updateProductionInstructionsLoadingFailed={(details) => productInstructionLoadFailed(details)}
                            updateProductionInstructionsLoaded={(value) => dispatch(productionInstructionsLoaded(value))}
                            onAdditionsComponentState={(value) => onAdditionsComponentState(value)}
                            onProductionInstructionComponentState={(value) => onProductionInstructionComponentState(value)}
                        />
                        : <></>
                }

            </LoadingOverlayContainer>
        </>
    );
}