import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FlexBox, Left, PrimaryButtonComponent, Right } from "shared/shared";
import { useAppDispatch, useAppSelector } from "app/hook";
import { useSnackbar } from 'notistack';
import { AppDispatch } from '../../app/redux_store';
import { createOneLeg, editOneLeg, getOneLegs } from './redux/onelegs_overview.thunks';
import { resetState, deleteOneLeg, resetDeleteOneLeg } from "./redux/onelegs_overview.slice";
import { OneLegTableComponent } from './components/oneleg_table.component';
import { OneLegDeleteDialogComponent } from './components/oneleg_delete_dialog.component';
import { useNavigate } from "react-router-dom";
import { setSearchFilter, setGridFilters } from "additions/addition_overview/redux/addition_overview.slice";
import { FootOptionDeleteDialogComponent } from "massmasks/footoptions_overview/components/footoption_delete_dialog.component";
import { deleteFootOption } from "massmasks/footoptions_overview/redux/footoptions_overview.slice";
import { editFootOption } from "massmasks/footoptions_overview/redux/footoptions_overview.thunks";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { getGridColumns, sortByMainProductLineModel } from "./onelegs_overview.helper";

export const OneLegsOverviewComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const state = useAppSelector((state) => state.one_leg_overview);

    useEffect(() => {
        dispatch(getOneLegs());

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.command.createOneLeg.status === "success") {
        navigate("/massmasks/onelegs/create");
    }

    if (state.command.editOneLeg.status === "success") {
        navigate(`/massmasks/onelegs/edit/${state.actualData.selectedOneLeg.id}`);
    }

    if (state.query.getOneLegs.status === "error") {
        enqueueSnackbar(state.query.getOneLegs.message, { variant: "error" });
        dispatch(resetState());
    }

    if (state.command.confirmDeleteOneLeg.status === "error") {
        enqueueSnackbar(state.command.confirmDeleteOneLeg.message, { variant: "error" });
        dispatch(resetDeleteOneLeg());
    }

    if (state.command.confirmDeleteOneLeg.status === "success") {
        enqueueSnackbar("Einbeinhose erfolgreich gelöscht", { variant: "success" });
        dispatch(resetDeleteOneLeg());
    }

    const isLoadingOneLegs = state.query.getOneLegs.status === "idle"
    || state.command.confirmDeleteOneLeg.status === "pending"
    || state.query.getOneLegs.status === "pending";

    const oneLegsAvailable = state.loadedData.oneLegs?.length > 0;

    return (
        <div>
            {
                state.actualData.showDeleteDialog && <OneLegDeleteDialogComponent />
            }
            <FlexBox>
                {
                    oneLegsAvailable &&
                    <Left>
                        <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Einbeinhosen suchen ..." />
                    </Left>
                }
                <Right>
                    <PrimaryButtonComponent
                        onClick={() => dispatch(createOneLeg())}>
                        <AddIcon /> Neue Einbeinhose
                    </PrimaryButtonComponent>
                </Right>
            </FlexBox>
            <div style={{ height: "20px"}}></div>
            {
                !oneLegsAvailable && !isLoadingOneLegs &&
                <div>Keine Fußoptionen verfügbar</div>
            }
            {
                (oneLegsAvailable || isLoadingOneLegs) && 
                <OverviewDatagrid
                    isLoading={isLoadingOneLegs}
                    columns={getGridColumns(
                        (oneleg) => dispatch(editOneLeg(oneleg)),
                        (oneleg) => dispatch(deleteOneLeg(oneleg))
                    )}
                    rows={state.actualData.oneLegs}
                    loadedRowsCount={state.loadedData.oneLegs.length}
                    sortModel={sortByMainProductLineModel}
                    onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                    autoRowHeight={true}
                    onDoubleClickRow={(oneleg) => dispatch(editOneLeg(oneleg))}
                />
            }
        </div>
        );
};