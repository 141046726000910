import { createAsyncThunk } from '@reduxjs/toolkit';
import IAdditionOverview from '../../../models/additions/addition_overview';
import AdditionService from '../../../services/addition.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';
import { RootState } from 'app/redux_store';

export const getAdditions = createAsyncThunk(
    'addition/overview/getAdditions',
    async (_) => {
        const response = await AdditionService.getAdditionsOverview();
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const togglePublishAddition = createAsyncThunk(
    'addition/overview/togglePublishAddition',
    async (addition: IAdditionOverview, { dispatch }) => {
        if (addition.isPublished) {
            await dispatch(unpublishAddition(addition));
        } else {
            await dispatch(publishAddition(addition));
        }
    }
)

export const publishAddition = createAsyncThunk(
    'addition/overview/publishAddition',
    async (addition: IAdditionOverview, thunkApi) => {
        const responsePublish = await AdditionService.publishAddition(addition);
        handleResponseErrors(responsePublish, "Zusatz");
        const responseAdditions = await AdditionService.getAdditionsOverview();
        handleResponseErrors(responseAdditions, "Zusätze");
        return responseAdditions;
    }
)


export const unpublishAddition = createAsyncThunk(
    'addition/overview/unpublishAddition',
    async (addition: IAdditionOverview, thunkApi) => {
        const responseUnpublish = await AdditionService.unpublishAddition(addition);
        handleResponseErrors(responseUnpublish, "Zusatz");
        const responseAdditions = await AdditionService.getAdditionsOverview();
        handleResponseErrors(responseAdditions, "Zusätze");
        return responseAdditions;
    }
)

export const confirmDeleteAddition = createAsyncThunk(
    'addition/overview/confirmDeleteAddition',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const activeAdditionId = state.addition_overview.actualData.activeAddition.id

        const deleteResponse = await AdditionService.deleteAddition(activeAdditionId);
        handleResponseErrors(deleteResponse, "Zusatz");

        const additionsResponse = await AdditionService.getAdditionsOverview();
        handleResponseErrors(additionsResponse, "Zusätze");
        return additionsResponse;
    }
)

export const createNewAddition = createAsyncThunk(
    'addition/overview/createNewAddition',
    async (_) => { }
)

export const updateAddition = createAsyncThunk(
    'addition/overview/updateAddition',
    async (addition: IAdditionOverview) => { 
        return addition;
    }
)
