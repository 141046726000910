import { AdditionState } from './addition.model';
import { doesExist, isNotEmpty, isNullOrWhitespace } from 'services/validation.service';
import {
    IAdditionViewModelScope,
    IProductLineViewModel,
    IBodyAreaViewModel,
    IPlaceholderViewModel,
    IAdditionExcludeViewModel,
    CountryFilterList,
    AvailableCategory,
    AttributeForm,
    AttributeFilterList,
    CategorySelection,
    PrintAreaEnumValues,
    IQualitySelectionViewModel,
    IArticleTypeSelectionViewModel,
    ArticleTypeFilterList,
    QualityFilterList,
    IAdditionEditor,
    IAdditionGussetViewModel,
    IPlaceholderConfigViewModel,
    IPlaceholderQualityConfigViewModel,
} from 'models/additions/addition_editor.model';
import
IAddition, {
    AdditionCategoryType,
    AdditionCountry,
    IQualityAdditionScope,
    IAdditionAttributeCategory,
    IArticleTypeAdditionScope,
    IPlaceholder,
    AdditionFootType,
    IAdditionGussetArticleType,
    IAdditionGussetQuality,
    IPlaceholderQualityConfig,
} from 'models/additions/addition';
import IAdditionCreate, {
    IAdditionScopeSave,
    IAdditionArticleTypeScopeSave,
    IAdditionQualityScopeSave,
    IPlaceholderSave,
    IAdditionExcludeSave,
    IAdditionCountrySave,
    IAddtionAttributeCategorySave,
    IAdditionCategoryTypeEnum,
    IAdditionAttributeRequestSave,
    IAdditionFootTypeSave,
    IAdditionGussetSave,
    IPlaceholderConfigSave
} from 'models/additions/addition_create';
import IAdditionUpdate from 'models/additions/addition_update';
import IAvailableProductLine from 'models/available_basedata/available_product_line';
import IAvailableBodyArea from 'models/available_basedata/available_body_area';
import IAvailableArticleType from 'models/available_basedata/available_article_type';
import IAvailableQuality from 'models/available_basedata/available_quality';
import { AdditionPlaceHolderTypeEnum } from 'models/additions/addition_placeholder.enum';
import { IGussetMassPointViewModel } from 'models/additions/addition_editor.model';
import { ICountry } from 'models/country/country';
import { IAvailableAdditionAttributeCategory } from 'models/addition_attribute_categories/available_addition_attribute_category';
import { IAvailableAdditionAttribute } from 'models/addition_attribute_categories/available_addition_attribute';
import IAvailableFootType from 'models/available_basedata/available_foot_type';
import { MasspointTypeEnumValuesLookup } from 'models/masspoints/enums/masspoint_type.enum';
import { filterArticleTypes, filterQualities } from 'additions/common/helpers/filters';
import { calculateArticleTypeSelection, calculateQualitiesSelection, getSelectedQualitiesFromScopes, hasValidValidityScopes } from 'additions/common/helpers/validityScope';
import { IIndexedIds } from 'check/policy/policy_adjust_ranges_by_masspoint/components/policy_adjust_ranges_by_masspoint_configuration.model';
import { ISelectable } from 'shared/components/selectionComponent/models/selectable';
import { LockTypeEnum } from 'shared/components/selectionComponent/models/locktype.enum';
import { IAdditionBaseDataResult } from 'models/additions/additionBaseData';

const placeholderValid = (placeholder: IPlaceholderViewModel) => {

    const defaultPlaceholderValid = isConfigValid(placeholder.defaultConfig, placeholder.placeholderTemplate.value);
    const qualityPlaceholderValid = !placeholder.qualityConfigs.some(config => {
        return !isConfigValid(config.config, placeholder.placeholderTemplate.value) || !doesExist(config.quality)
    });

    return defaultPlaceholderValid && qualityPlaceholderValid;
}

const isConfigValid = (config: IPlaceholderConfigViewModel, placeholderTemplate: AdditionPlaceHolderTypeEnum): boolean => {

    if (!config) return false;

    return (placeholderTemplate === AdditionPlaceHolderTypeEnum.None
        || (placeholderTemplate === AdditionPlaceHolderTypeEnum.TextInput
            && config.textInputPlaceholderVersion.textInputMaxLength >= 1
            && config.textInputPlaceholderVersion.textInputMaxLength <= 999)
        || (placeholderTemplate === AdditionPlaceHolderTypeEnum.RangeInput
            && config.rangePlaceholderVersion.minimalValue >= 0
            && config.rangePlaceholderVersion.minimalValue <= 999.9
            && config.rangePlaceholderVersion.maximalValue >= 0.1
            && config.rangePlaceholderVersion.maximalValue <= 999.9
            && config.rangePlaceholderVersion.minimalValue < config.rangePlaceholderVersion.maximalValue)
        || (placeholderTemplate === AdditionPlaceHolderTypeEnum.TwoRangeInput
            && config.twoRangePlaceholderVersion.firstMinimalValue < config.twoRangePlaceholderVersion.firstMaximalValue
            && config.twoRangePlaceholderVersion.secondMinimalValue < config.twoRangePlaceholderVersion.secondMaximalValue
            && config.twoRangePlaceholderVersion.secondMaximalValue >= 0.1
            && config.twoRangePlaceholderVersion.firstMaximalValue >= 0.1
            && config.twoRangePlaceholderVersion.secondMaximalValue <= 999.9
            && config.twoRangePlaceholderVersion.firstMaximalValue <= 999.9
            && config.twoRangePlaceholderVersion.secondMinimalValue >= 0
            && config.twoRangePlaceholderVersion.firstMinimalValue >= 0
            && config.twoRangePlaceholderVersion.firstMinimalValue <= 999.9
            && config.twoRangePlaceholderVersion.firstMinimalValue <= 999.9))
}

const isGussetValid = (additionGussets: IAdditionGussetViewModel[], isGusset: boolean) => {
    var isValid = true;

    isValid = (!isGusset || (isGusset && additionGussets.length > 0));

    additionGussets.forEach(gusset => {
        if (gusset.additionGussetArticleTypes.selectedList.allItems.length <= 0
            || gusset.additionGussetQualities.selectedList.allItems.length <= 0
            || gusset.additionGussetMassPoints.length <= 0
            || isValid === false
            || !doesExist(gusset.priority)) {
            isValid = false;
        } else {
            gusset.additionGussetMassPoints.forEach(masspoint => {
                if (!doesExist(masspoint.maximalValue)
                    || !doesExist(masspoint.minimalValue)
                    || masspoint.minimalValue >= masspoint.maximalValue
                    || isNullOrWhitespace(masspoint.displayName)) {
                    isValid = false;
                }
            });
        }
    });

    return isValid;
}

export const updateCanSaveAddition = (state: AdditionState) => {
    state.command.updateAddition.canExecute = doesExist(state.actualData.additionEditor.addition)
        && placeholderValid(state.actualData.additionEditor.addition.placeholder)
        && doesExist(state.actualData.additionEditor.addition.side)
        && hasValidValidityScopes(state.actualData.additionEditor, state.loadedData.additionInitial)
        && isFootTypeValid(state)
        && isPositioningValid(state.actualData.additionEditor.selectedPositioningOption.value, state.actualData.additionEditor.positionCategorySelection.selectedList.allItems, state.actualData.additionEditor.addition.positionCode)
        && isGussetValid(state.actualData.additionEditor.addition.additionGussets, state.actualData.additionEditor.addition.isGusset.value)
}

const isPositioningValid = (selectedPositioningOption: boolean, selectionPositioningCategories: ISelectable[],
    positionCode: string): boolean => {
    return (!selectedPositioningOption || (selectionPositioningCategories.length > 0 && positionCodeIsValid(positionCode)))
}

const positionCodeIsValid = (positionCode: string): boolean => {
    let isValid = positionCode != "";
    if (isValid) {
        const regexp = new RegExp('^[A-Z0-9]{3}$');
        isValid = regexp.test(positionCode);
    }
    return isValid;
}

const isFootTypeValid = (state: AdditionState): boolean => {
    const locktypeEnabled = state.actualData.additionEditor.addition.lockFootType;
    const hasLockedFootTypes = state.actualData.additionEditor.footTypeSelection.selectedList.allItems.length > 0;

    let isValid = true;
    if (locktypeEnabled && !hasLockedFootTypes) {
        isValid = false;
    }
    return isValid;
}

export const setAdditionDefinition = (state: AdditionState, addition: IAddition) => {
    state.actualData.additionEditor.addition.id = addition.id;
    state.actualData.additionEditor.addition.option = addition.option;
    state.actualData.additionEditor.addition.code = addition.code;
    state.actualData.additionEditor.addition.hasCalingaDescription = addition.hasCalingaDescription;
    state.actualData.additionEditor.addition.description = addition.description;
    state.actualData.additionEditor.addition.baseDescription = addition.description;
    state.actualData.additionEditor.addition.descriptionLong = addition.descriptionLong;
    state.actualData.additionEditor.addition.quantityCode = addition.quantityCode;
    state.actualData.additionEditor.addition.quantityText = addition.quantityText;
    state.actualData.additionEditor.addition.positionCode = addition.positionCode;
    state.actualData.additionEditor.addition.side = state.loadedData.additionInitial.sides.find(x => x.value === addition.side);
    state.actualData.additionEditor.addition.createOrderPosition = state.loadedData.additionInitial.createOrderPositionOptions.find(x => x.value === addition.createOrderPosition);
    state.actualData.additionEditor.addition.calculateOrderPosition = state.loadedData.additionInitial.calculateOrderPosition.find(x => x.value === addition.calculateOrderPosition);
    state.actualData.additionEditor.addition.automaticProduction = state.loadedData.additionInitial.automatedProductions.find(x => x.value === addition.automaticProduction);
    state.actualData.additionEditor.addition.orderManagement = state.loadedData.additionInitial.orderManagementOptions.find(x => x.value === addition.orderManagement);
    state.actualData.additionEditor.addition.reOrder = state.loadedData.additionInitial.reorderOptions.find(x => x.value === addition.reOrder);
    state.actualData.additionEditor.addition.countryLock.lockCountry = addition.isCountryLocked;
    state.actualData.additionEditor.addition.countryLock.countryLockType = addition.countryLockType;
    state.actualData.additionEditor.addition.lockFootType = addition.isFootTypeLocked;
    state.actualData.additionEditor.addition.printArea = PrintAreaEnumValues.find(x => x.value === addition.printArea);
    state.actualData.additionEditor.addition.placeholder = toPlaceholderViewModel(addition.placeholderVersion, state);
    state.actualData.additionEditor.addition.category = state.loadedData.additionInitial.categories.find(x => x.id === addition.category.id);
    state.actualData.additionEditor.addition.isFullyKnitted = state.loadedData.additionInitial.isFullyKnitted.find(x => x.value === addition.isFullyKnitted);
    state.actualData.additionEditor.addition.isRecipeValid = state.loadedData.additionInitial.isRecipeValidOptions.find(x => x.value === addition.isRecipeValid);
    state.actualData.additionEditor.addition.isGusset = state.loadedData.additionInitial.isGussetOptions.find(x => x.value === addition.isGusset);
    state.actualData.additionEditor.addition.isMultiply = state.loadedData.additionInitial.isMultiplyOptions.find(x => x.value === addition.isMultiply);
    state.actualData.additionEditor.addition.compressionClass = state.loadedData.additionInitial.compressionClass.find(x => x.value === addition.compressionClass);
    state.actualData.additionEditor.addition.productionType = state.loadedData.additionInitial.productionTypes.find(x => x.value === addition.productionType);
    state.actualData.additionEditor.selectedPositioningOption = state.loadedData.additionInitial.positioningOptions.find(x => x.value === addition.isPositioned);
    state.actualData.additionEditor.selectedCharacteristicCategoryOption = state.loadedData.additionInitial.characteristicCategoryOptions.find(x => x.value === addition.isCharacteristic);

    addition.assignedPositionCategoryVersions.forEach(x => {
        const positionCategory = state.actualData.additionEditor.positionCategorySelection.unSelectedList.allItems.find(y => y.id === x.positionCategoryId);
        if (positionCategory) {
            state.actualData.additionEditor.positionCategorySelection.selectedList.allItems.push(positionCategory);
            const index = state.actualData.additionEditor.positionCategorySelection.unSelectedList.allItems.findIndex(y => y.id === x.positionCategoryId);
            state.actualData.additionEditor.positionCategorySelection.unSelectedList.allItems.splice(index, 1);
        }
    });

    state.actualData.additionEditor.addition.additionGussets = addition.additionGussets.map((item, index) => {
        let assignedMasspoints = [];
        let availableMasspoints = [];
        state.loadedData.additionInitial.masspoints.forEach(masspoint => {
            const masspointAssignedIndex = item.additionGussetMassPoints.findIndex(gussetMassPoint =>
                gussetMassPoint.name === masspoint.name
                && gussetMassPoint.type === masspoint.type
                && gussetMassPoint.bodyAreaId === masspoint.bodyAreaId)
            if (masspointAssignedIndex !== -1) {
                assignedMasspoints.push({
                    displayName: `${masspoint.name} / ${MasspointTypeEnumValuesLookup(masspoint.type)} / ${masspoint.bodyArea.name}`,
                    name: masspoint.name,
                    type: masspoint.type,
                    maximalValue: item.additionGussetMassPoints[masspointAssignedIndex].maximalValue,
                    minimalValue: item.additionGussetMassPoints[masspointAssignedIndex].minimalValue,
                    bodyAreaId: masspoint.bodyAreaId,
                    bodyArea: masspoint.bodyArea
                });
            } else {
                availableMasspoints.push({
                    displayName: `${masspoint.name} / ${MasspointTypeEnumValuesLookup(masspoint.type)} / ${masspoint.bodyArea.name}`,
                    name: masspoint.name,
                    type: masspoint.type,
                    maximalValue: null,
                    minimalValue: null,
                    bodyAreaId: masspoint.bodyAreaId,
                    bodyArea: masspoint.bodyArea
                });
            }
        })

        return {
            priority: item.priority,
            additionGussetArticleTypes: toGussetArticleTypes(state.loadedData.additionInitial.articleTypes, item.additionGussetArticleTypes),
            additionGussetQualities: toGussetQualities(state.loadedData.additionInitial.qualities, item.additionGussetQualities),
            additionGussetMassPoints: assignedMasspoints,
            availableGussetMassPointModels: availableMasspoints,
            index: index
        }
    })

    var additionScopes: IAdditionViewModelScope[] = [];

    addition.additionScopeVersions.forEach(item => {
        let scope = {
            additionId: item.additionId,
            bodyArea: {
                id: item.bodyArea.id,
                name: item.bodyArea.name,
            },
            mainProductLine: {
                id: item.mainProductLine.id,
                name: item.mainProductLine.name,
            },
            articleTypes: toArticleTypeViewModel(item.articleTypeValidityType, item.additionScopeArticleTypeVersions),
            qualities: toQualityViewModel(item.qualityValidityType, item.additionScopeQualityVersions),
        };

        scope.articleTypes = calculateArticleTypeSelection(scope, state.loadedData.additionInitial);
        scope.qualities = calculateQualitiesSelection(scope, state.loadedData.additionInitial);
        additionScopes.push(scope);
    })

    state.actualData.additionEditor.addition.additionScopes = additionScopes;

    state.actualData.additionEditor.selectedLockCountry = state.loadedData.additionInitial.lockCountryOptions.find(x => x.value === addition.isCountryLocked);
    updateCanSaveAddition(state);
}

const toGussetArticleTypes = (loadedArticleTypes: IAvailableArticleType[],
    gussetArticleTypes: IAdditionGussetArticleType[]) => {
    let selectedItems: IAvailableArticleType[] = [];
    let unSelectedList: IAvailableArticleType[] = [];

    loadedArticleTypes.forEach(item => {
        const index = gussetArticleTypes.findIndex(x => x.articleTypeId === item.id)
        if (index >= 0) {
            selectedItems.push(item);
        } else {
            unSelectedList.push(item);
        }
    });
    return {
        lockType: LockTypeEnum.Undefined,
        selectedList: {
            searchText: "",
            allItems: selectedItems,
            filteredItems: selectedItems
        },
        unSelectedList: {
            searchText: "",
            allItems: unSelectedList,
            filteredItems: unSelectedList
        }
    }
}

const toGussetQualities = (loadedQualities: IAvailableQuality[], gussetQualities: IAdditionGussetQuality[]) => {
    let selectedItems: IAvailableQuality[] = [];
    let unSelectedList: IAvailableQuality[] = [];

    loadedQualities.forEach(item => {
        const index = gussetQualities.findIndex(x => x.qualityId === item.id)
        if (index >= 0) {
            selectedItems.push(item);
        } else {
            unSelectedList.push(item);
        }
    });
    return {
        lockType: LockTypeEnum.Undefined,
        selectedList: {
            searchText: "",
            allItems: selectedItems,
            filteredItems: selectedItems
        },
        unSelectedList: {
            searchText: "",
            allItems: unSelectedList,
            filteredItems: unSelectedList
        }
    }
}


const toArticleTypeViewModel = (lockType: LockTypeEnum,
    articleTypeScopes: IArticleTypeAdditionScope[]): IArticleTypeSelectionViewModel => {

    let selectedItems: IAvailableArticleType[] = articleTypeScopes.map(x => x.articleType);

    return {
        lockType: lockType,
        selectedList: {
            searchText: "",
            allItems: selectedItems,
            filteredItems: selectedItems
        },
        unSelectedList: {
            searchText: "",
            allItems: [],
            filteredItems: []
        }
    };
};

const toQualityViewModel = (lockType: LockTypeEnum, qualityScopes: IQualityAdditionScope[]): IQualitySelectionViewModel => {

    let selectedItems: IAvailableQuality[] = qualityScopes.map(x => x.quality);

    return {
        lockType: lockType,
        selectedList: {
            searchText: "",
            allItems: selectedItems,
            filteredItems: selectedItems
        },
        unSelectedList: {
            searchText: "",
            allItems: [],
            filteredItems: []
        }
    };
};

export const getNewConfig = () => {
    return {
        textInputPlaceholderVersion: {
            textInputMaxLength: 999
        },
        rangePlaceholderVersion: {
            minimalValue: 1,
            maximalValue: 999
        },
        twoRangePlaceholderVersion: {
            firstMinimalValue: 1,
            firstMaximalValue: 2,
            secondMinimalValue: 998,
            secondMaximalValue: 999
        },
    } as IPlaceholderConfigViewModel
}

export const recalculateAvailableQualitiesForPlaceholderConfigs = (state: AdditionState) => {
    state.actualData.additionEditor.addition.placeholder.qualityConfigs.forEach((config, index) => {
        const selectableQualites = getSelectableQualitiesForPlaceholderConfigs(state.actualData.additionEditor, index);
        config.selectableQualites = selectableQualites;
        if (config.quality && !selectableQualites.some(quality => quality.id === config.quality.id)) {
            config.quality = null;
        }
    })
}

const getSelectableQualitiesForPlaceholderConfigs = (additionEditor: IAdditionEditor, qualityConfigIndex: number) => {
    const additionScopes = additionEditor.addition.additionScopes;
    const selectableQualities = getSelectedQualitiesFromScopes(additionScopes);
    const alreadySelectedQualities: IAvailableQuality[] = [];
    additionEditor.addition.placeholder.qualityConfigs.forEach((config, index) => {
        if (config.quality && index !== qualityConfigIndex) {
            alreadySelectedQualities.push(config.quality);
        }
    })

    return selectableQualities.filter(selectableQuality => !alreadySelectedQualities.some(aSQ => aSQ.id === selectableQuality.id));
}

const toPlaceholderViewModel = (additionPlaceholder: IPlaceholder, state: AdditionState): IPlaceholderViewModel => {
    const placeholder: IPlaceholderViewModel = {
        placeholderTemplate: state.loadedData.additionInitial.placeholderTypes.find(x => x.value === AdditionPlaceHolderTypeEnum.None),
        defaultConfig: getNewConfig(),
        qualityConfigs: [],
        twoRangePlaceholderIsSideSpecific: true,
        isInteger: false
    };

    if (additionPlaceholder != null && additionPlaceholder.placeholderTemplate != null) {
        placeholder.placeholderTemplate = state.loadedData.additionInitial.placeholderTypes.find(x => x.value === additionPlaceholder.placeholderTemplate);
        switch (additionPlaceholder.placeholderTemplate) {
            case AdditionPlaceHolderTypeEnum.TextInput:
                placeholder.defaultConfig.textInputPlaceholderVersion.textInputMaxLength = additionPlaceholder.defaultTextInputPlaceholderVersion.textInputMaxLength;
                break;
            case AdditionPlaceHolderTypeEnum.RangeInput:
                placeholder.defaultConfig.rangePlaceholderVersion.minimalValue = additionPlaceholder.defaultRangePlaceholderVersion.minimalValue;
                placeholder.defaultConfig.rangePlaceholderVersion.maximalValue = additionPlaceholder.defaultRangePlaceholderVersion.maximalValue;
                break;
            case AdditionPlaceHolderTypeEnum.TwoRangeInput:
                placeholder.defaultConfig.twoRangePlaceholderVersion.firstMinimalValue = additionPlaceholder.defaultTwoRangePlaceholderVersion.firstMinimalValue;
                placeholder.defaultConfig.twoRangePlaceholderVersion.firstMaximalValue = additionPlaceholder.defaultTwoRangePlaceholderVersion.firstMaximalValue;
                placeholder.defaultConfig.twoRangePlaceholderVersion.secondMinimalValue = additionPlaceholder.defaultTwoRangePlaceholderVersion.secondMinimalValue;
                placeholder.defaultConfig.twoRangePlaceholderVersion.secondMaximalValue = additionPlaceholder.defaultTwoRangePlaceholderVersion.secondMaximalValue;
                break;
        }
        placeholder.qualityConfigs = additionPlaceholder.qualityConfigs.map(qC => toPlaceholderConfigViewModel(qC, additionPlaceholder.placeholderTemplate, state.loadedData.additionInitial.qualities))
        placeholder.twoRangePlaceholderIsSideSpecific = additionPlaceholder.twoRangePlaceholderIsSideSpecific;
        placeholder.isInteger = additionPlaceholder.isInteger;
    }
    return placeholder;
}

const toPlaceholderConfigViewModel = (sourceConfig: IPlaceholderQualityConfig, placeholderTemplate: AdditionPlaceHolderTypeEnum, qualities: IAvailableQuality[]) => {
    let newConfig = { quality: qualities.find(q => q.id === sourceConfig.qualityId), config: getNewConfig() } as IPlaceholderQualityConfigViewModel

    switch (placeholderTemplate) {
        case AdditionPlaceHolderTypeEnum.TextInput:
            newConfig.config.textInputPlaceholderVersion.textInputMaxLength = sourceConfig.textInputPlaceholderVersion.textInputMaxLength;
            break;
        case AdditionPlaceHolderTypeEnum.RangeInput:
            newConfig.config.rangePlaceholderVersion.minimalValue = sourceConfig.rangePlaceholderVersion.minimalValue;
            newConfig.config.rangePlaceholderVersion.maximalValue = sourceConfig.rangePlaceholderVersion.maximalValue;
            break;
        case AdditionPlaceHolderTypeEnum.TwoRangeInput:
            newConfig.config.twoRangePlaceholderVersion.firstMinimalValue = sourceConfig.twoRangePlaceholderVersion.firstMinimalValue;
            newConfig.config.twoRangePlaceholderVersion.firstMaximalValue = sourceConfig.twoRangePlaceholderVersion.firstMaximalValue;
            newConfig.config.twoRangePlaceholderVersion.secondMinimalValue = sourceConfig.twoRangePlaceholderVersion.secondMinimalValue;
            newConfig.config.twoRangePlaceholderVersion.secondMaximalValue = sourceConfig.twoRangePlaceholderVersion.secondMaximalValue;
            break;
    }

    return newConfig;
}

export const toIAdditionUpdate = (additionEditor: IAdditionEditor,
    categoryAttributes: CategorySelection): IAdditionUpdate => {
    const additionViewModel = additionEditor.addition;
    return {
        id: additionViewModel.id,
        side: additionViewModel.side.value,
        createOrderPosition: additionViewModel.createOrderPosition.value,
        calculateOrderPosition: additionViewModel.calculateOrderPosition.value,
        orderManagement: additionViewModel.orderManagement.value,
        reOrder: additionViewModel.reOrder.value,
        countryLocked: additionViewModel.countryLock.lockCountry,
        countryLockType: additionViewModel.countryLock.countryLockType,
        footTypeLocked: additionViewModel.lockFootType,
        footTypeLockType: additionEditor.footTypeSelection.lockType,
        additionFootTypes: additionEditor.footTypeSelection.selectedList.allItems.map(x=> ({id: x.id})),
        automaticProduction: additionViewModel.automaticProduction.value,
        printArea: additionViewModel.printArea.value,
        categoryId: additionViewModel.category.id,
        isPositioned: additionEditor.selectedPositioningOption.value,
        isFullyKnitted: additionViewModel.isFullyKnitted.value,
        isGusset: additionViewModel.isGusset.value,
        isMultiply: additionViewModel.isMultiply.value,
        isRecipeValid: additionViewModel.isRecipeValid.value,
        compressionClass: additionViewModel.compressionClass.value,
        productionType: additionViewModel.productionType.value,
        placeholder: toAdditionPlaceholder(additionViewModel.placeholder),
        additionScopes: toIAdditionScopes(additionViewModel.additionScopes),
        additionExcludes: additionEditor.additionExcludeCriteriaSelection.selectedList.allItems.map(x => {
            return {
                additionId: x.id
            }
        }),
        additionGussets: toIAdditionGussets(additionViewModel.additionGussets),
        additionCountries: toIAdditionCountries(additionViewModel.countryLock.countryLocks),
        additionAttributeCategories: toIAdditionAttributeCategories(categoryAttributes),
        assignedPositionCategories: additionEditor.selectedPositioningOption.value === true ? additionEditor.positionCategorySelection.selectedList.allItems.map(x => {
            return {
                positionCategoryId: x.id
            }
        }) : [],
        positionCode: additionViewModel.positionCode,
        isCharacteristic: additionEditor.selectedCharacteristicCategoryOption.value,
        characteristicCategoryId: additionEditor.selectedCharacteristicCategoryOption.value === true ? additionEditor.selectedcharacteristicCategoryId : undefined
    }
}

export const toIAdditionCreate = (additionEditor: IAdditionEditor, categoryAttributes: CategorySelection): IAdditionCreate => {
    const additionViewModel = additionEditor.addition;
    return {
        option: additionViewModel.option,
        side: additionViewModel.side.value,
        quantityText: additionViewModel.quantityText,
        quantityCode: additionViewModel.quantityCode,
        description: additionViewModel.baseDescription,
        code: additionViewModel.code,
        categoryId: additionViewModel.category.id,
        createOrderPosition: additionViewModel.createOrderPosition.value,
        calculateOrderPosition: additionViewModel.calculateOrderPosition.value,
        orderManagement: additionViewModel.orderManagement.value,
        reOrder: additionViewModel.reOrder.value,
        countryLocked: additionViewModel.countryLock.lockCountry,
        countryLockType: additionViewModel.countryLock.countryLockType,
        automaticProduction: additionViewModel.automaticProduction.value,
        isFullyKnitted: additionViewModel.isFullyKnitted.value,
        isPositioned: additionEditor.selectedPositioningOption.value,
        isRecipeValid: additionViewModel.isRecipeValid.value,
        isGusset: additionViewModel.isGusset.value,
        isMultiply: additionViewModel.isMultiply.value,
        compressionClass: additionViewModel.compressionClass.value,
        productionType: additionViewModel.productionType.value,
        printArea: additionViewModel.printArea.value,
        placeholder: toAdditionPlaceholder(additionViewModel.placeholder),
        additionGussets: toIAdditionGussets(additionViewModel.additionGussets),
        additionScopes: toIAdditionScopes(additionViewModel.additionScopes),
        additionExcludes: additionEditor.additionExcludeCriteriaSelection.selectedList.allItems.map(x => {
            return {
                additionId: x.id
            }
        }),
        additionCountries: toIAdditionCountries(additionViewModel.countryLock.countryLocks),
        additionAttributeCategories: toIAdditionAttributeCategories(categoryAttributes),
        footTypeLocked: additionViewModel.lockFootType,
        footTypeLockType: additionEditor.footTypeSelection.lockType,
        additionFootTypes: additionEditor.footTypeSelection.selectedList.allItems.map(x=> ({id: x.id})),
        assignedPositionCategories: additionEditor.selectedPositioningOption.value === true ? additionEditor.positionCategorySelection.selectedList.allItems.map(x => {
            return {
                positionCategoryId: x.id
            }
        }) : [],
        positionCode: additionViewModel.positionCode,
        isCharacteristic: additionEditor.selectedCharacteristicCategoryOption.value,
        characteristicCategoryId: additionEditor.selectedCharacteristicCategoryOption.value === true ? additionEditor.selectedcharacteristicCategoryId : undefined,
    }
}

const toIAdditionGussets = (gussets: IAdditionGussetViewModel[]): IAdditionGussetSave[] => {
    return gussets.map(item => {
        return {
            priority: item.priority,
            additionGussetArticleTypes: item.additionGussetArticleTypes.selectedList.allItems.map(articleType => {
                return {
                    articleTypeId: articleType.id
                }
            }),
            additionGussetQualities: item.additionGussetQualities.selectedList.allItems.map(quality => {
                return {
                    qualityId: quality.id
                }
            }),
            additionGussetMassPoints: item.additionGussetMassPoints.map(point => {
                return {
                    name: point.name,
                    type: point.type,
                    maximalValue: point.maximalValue,
                    minimalValue: point.minimalValue,
                    bodyAreaId: point.bodyAreaId
                }
            })
        }
    });
}

const toIAdditionFootTypes = (footTypes: IAvailableFootType[]): IAdditionFootTypeSave[] => {
    var additionFootTypes: IAdditionFootTypeSave[] = [];
    footTypes.forEach(item => {
        additionFootTypes.push({
            id: item.id
        });
    });
    return additionFootTypes;
}

const toIAdditionAttributeCategories = (categoryAttributes: CategorySelection): IAddtionAttributeCategorySave[] => {
    var additionCategories: IAddtionAttributeCategorySave[] = [];
    categoryAttributes.attributeForms.forEach(attributeForm => {
        if (attributeForm.selectedCategory != undefined) {
            additionCategories.push({
                type: attributeForm.isLocked ? IAdditionCategoryTypeEnum.Blacklist : IAdditionCategoryTypeEnum.Whitelist,
                availableAdditionAttributeCategoryId: attributeForm.selectedCategory.id,
                additionAttributes: toAttributes(attributeForm.selectedList.allItems),
            });
        }
    });
    return additionCategories;
}

const toAttributes = (availableAttributes: IAvailableAdditionAttribute[]): IAdditionAttributeRequestSave[] => {
    var additionAttributes: IAdditionAttributeRequestSave[] = [];
    availableAttributes.forEach(item => {
        additionAttributes.push({
            availableAdditionAttributeId: item.id
        });
    });
    return additionAttributes;
}

const toIAdditionCountries = (countries: ICountry[]): IAdditionCountrySave[] => {
    var additionCountries: IAdditionCountrySave[] = [];
    countries.forEach(item => {
        additionCountries.push({
            id: item.id
        });
    });
    return additionCountries;
}

export const toIAdditionExcludes = (additionExcludesViewModels: IAdditionExcludeViewModel[]): IAdditionExcludeSave[] => {
    var additionExcludes: IAdditionExcludeSave[] = [];
    additionExcludesViewModels.forEach(item => {
        additionExcludes.push({
            additionId: item.id
        });
    });
    return additionExcludes;
}

export const toAdditionPlaceholder = (placeholderViewModel: IPlaceholderViewModel): IPlaceholderSave => {
    const placeholder: IPlaceholderSave = {
        placeholderTemplate: placeholderViewModel.placeholderTemplate.value,
        defaultConfig: toPlaceholderConfigSave(placeholderViewModel.defaultConfig, placeholderViewModel.placeholderTemplate.value),
        qualityConfigs: placeholderViewModel.qualityConfigs.map(config => ({
            qualityId: config.quality.id,
            config: toPlaceholderConfigSave(config.config, placeholderViewModel.placeholderTemplate.value)
        })),
        twoRangePlaceholderIsSideSpecific: placeholderViewModel.twoRangePlaceholderIsSideSpecific,
        isInteger: placeholderViewModel.isInteger
    };
    return placeholder;
}

const toPlaceholderConfigSave = (config: IPlaceholderConfigViewModel, placeholderTemplate: AdditionPlaceHolderTypeEnum): IPlaceholderConfigSave => {
    return {
        textInputPlaceholder: placeholderTemplate === AdditionPlaceHolderTypeEnum.TextInput ?
            {
                textInputMaxLength: config.textInputPlaceholderVersion.textInputMaxLength,
            } : null,
        rangeInputPlaceholder: placeholderTemplate === AdditionPlaceHolderTypeEnum.RangeInput ?
            {
                minimalValue: config.rangePlaceholderVersion.minimalValue,
                maximalValue: config.rangePlaceholderVersion.maximalValue,
            } : null,
        twoRangeInputPlaceholder: placeholderTemplate === AdditionPlaceHolderTypeEnum.TwoRangeInput ?
            {
                firstMinimalValue: config.twoRangePlaceholderVersion.firstMinimalValue,
                firstMaximalValue: config.twoRangePlaceholderVersion.firstMaximalValue,
                secondMinimalValue: config.twoRangePlaceholderVersion.secondMinimalValue,
                secondMaximalValue: config.twoRangePlaceholderVersion.secondMaximalValue,
            } : null,
    }
}

export const toIAdditionScopes = (additionScopesViewModels: IAdditionViewModelScope[]): IAdditionScopeSave[] => {
    var additionScopes: IAdditionScopeSave[] = [];
    additionScopesViewModels.forEach(item => {
        additionScopes.push({
            mainProductLineId: item.mainProductLine.id,
            bodyAreaId: item.bodyArea.id,
            articleTypeValidityType: item.articleTypes.lockType,
            qualityValidityType: item.qualities.lockType,
            articleTypes: toIAdditonArticleTypeScopes(item.articleTypes.selectedList.allItems),
            qualities: toIAdditionQualityScopes(item.qualities.selectedList.allItems)
        });
    });
    return additionScopes;
}

export const toIAdditonArticleTypeScopes = (additionArticleTypeScopesViewModels: IAvailableArticleType[]): IAdditionArticleTypeScopeSave[] => {
    var additionArticleTypeScopes: IAdditionArticleTypeScopeSave[] = [];
    additionArticleTypeScopesViewModels.forEach(item => {
        additionArticleTypeScopes.push({
            articleTypeId: item.id,
        });
    });
    return additionArticleTypeScopes;
}

export const toIAdditionQualityScopes = (additionQualityScopesViewModels: IAvailableQuality[]): IAdditionQualityScopeSave[] => {
    var additionQualityScopes: IAdditionQualityScopeSave[] = [];
    additionQualityScopesViewModels.forEach(item => {
        additionQualityScopes.push({
            qualityId: item.id,
        });
    });
    return additionQualityScopes;
}

export const loadMainProductLines = (availableProductLines: IAvailableProductLine[]): IProductLineViewModel[] => {
    var mainProductlines: IProductLineViewModel[] = [];
    var countIndex: number = 0;
    const defaultMainProductline: IProductLineViewModel = {
        id: null,
        name: "",
    };
    mainProductlines.push(defaultMainProductline);
    countIndex++;
    availableProductLines.forEach(item => {
        const mainProductLine: IProductLineViewModel = {
            id: item.id,
            name: item.name,
        };
        mainProductlines.push(mainProductLine);
        countIndex++;
    });
    return mainProductlines;
}

export const loadBodyAreas = (availableBodyAreas: IAvailableBodyArea[]): IBodyAreaViewModel[] => {
    var bodyAreas: IBodyAreaViewModel[] = [];
    const defaultBodyArea: IBodyAreaViewModel = {
        id: null,
        name: "",
    };
    bodyAreas.push(defaultBodyArea);
    availableBodyAreas.forEach(item => {
        const bodyArea: IBodyAreaViewModel = {
            id: item.id,
            name: item.name,
        };
        bodyAreas.push(bodyArea);
    });
    return bodyAreas;
}

export const moveCountriesFromUnselectedToSelected = (state: AdditionState, countries: ICountry[]) => {
    const selectedList = state.actualData.additionEditor.selectedCountries.selectedList;
    const unselectedList = state.actualData.additionEditor.selectedCountries.unSelectedList;

    countries.forEach(country => {
        state.actualData.additionEditor.addition.countryLock.countryLocks.push(country);
        moveCountryFromSourceToDestination(unselectedList, selectedList, country);
    });

    filterCountries(selectedList);
    filterCountries(unselectedList);
}

export const moveCountriesFromSelectedToUnselected = (state: AdditionState, countries: ICountry[]) => {
    const selectedList = state.actualData.additionEditor.selectedCountries.selectedList;
    const unselectedList = state.actualData.additionEditor.selectedCountries.unSelectedList;

    countries.forEach(country => {
        const index = state.actualData.additionEditor.addition.countryLock.countryLocks.findIndex(ad => ad.id === country.id)
        state.actualData.additionEditor.addition.countryLock.countryLocks.splice(index, 1);
        moveCountryFromSourceToDestination(selectedList, unselectedList, country);
    });

    filterCountries(selectedList);
    filterCountries(unselectedList)
}

export const moveCountryFromSourceToDestination = (source: CountryFilterList, destination: CountryFilterList,
    country: ICountry) => {
    const indexInAll = source.allItems.findIndex(x => x.id === country.id);
    destination.allItems.push(country);
    source.allItems.splice(indexInAll, 1);
}

export const filterCountries = (listToFilter: CountryFilterList) => {
    listToFilter.filteredItems = listToFilter.allItems
        .filter(m => countryMatchesText(m, listToFilter.searchText))
        .sort(sortCountryCriteria);
}

export const countryMatchesText = (m: ICountry, searchText: string): boolean => {
    if (searchText === "") {
        return true;
    }
    const searchTextLowerCase = searchText.toLowerCase();
    return (m.name.toLowerCase().includes(searchTextLowerCase) || m.countryCode.toLowerCase().includes(searchTextLowerCase));
}

export const sortCountryCriteria = (mp1: ICountry, mp2: ICountry) => {
    return mp1.countryCode.localeCompare(mp2.countryCode);
}

export const moveAlreadySelectedCountries = (state: AdditionState, alreadySelectedCountrys: AdditionCountry[]) => {
    alreadySelectedCountrys.forEach(additionCountry => {
        const countryToAdd = state.actualData.additionEditor.selectedCountries.loadedCountries.find(x => x.id === additionCountry.countryId);
        moveCountriesFromUnselectedToSelected(state, [countryToAdd]);
    })
}

export const removeCategoryFromAvailableCategories = (state: AdditionState, category: AvailableCategory) => {
    const index = state.actualData.additionEditor.categories.availableCategories.findIndex(x => x.id === category.id);
    state.actualData.additionEditor.categories.availableCategories.splice(index, 1);
}

export const fillAvailableAttributesToAttributeForm = (attributeForm: AttributeForm,
    attributeCategories: IAvailableAdditionAttributeCategory[]) => {
    const category = attributeCategories.find(x => x.id === attributeForm.selectedCategory.id);
    attributeForm.unSelectedList.allItems = [...category.availableAdditionAttributes];
    attributeForm.unSelectedList.filteredItems = [...category.availableAdditionAttributes];
}

export const filterAttributes = (listToFilter: AttributeFilterList) => {
    listToFilter.filteredItems = listToFilter.allItems
        .filter(m => attributeMatchesText(m, listToFilter.searchText))
        .sort(sortAttributeCriteria);
}

export const attributeMatchesText = (m: IAvailableAdditionAttribute, searchText: string): boolean => {
    if (searchText === "") {
        return true;
    }
    const searchTextLowerCase = searchText.toLowerCase();
    return (m.name.toLowerCase().includes(searchTextLowerCase) || m.code.toLowerCase().includes(searchTextLowerCase));
}

export const sortAttributeCriteria = (mp1: IAvailableAdditionAttribute, mp2: IAvailableAdditionAttribute) => {
    return mp1.code.localeCompare(mp2.code);
}

export const moveAttributeFromUnselectedToSelected = (attributeForm: AttributeForm,
    items: IAvailableAdditionAttribute[]) => {
    const selectedList = attributeForm.selectedList;
    const unselectedList = attributeForm.unSelectedList;

    items.forEach(item => {
        moveAttributeFromSourceToDestination(unselectedList, selectedList, item);
    });

    filterAttributes(selectedList);
    filterAttributes(unselectedList);
}

export const moveAttributeFromSelectedToUnselected = (attributeForm: AttributeForm,
    items: IAvailableAdditionAttribute[]) => {
    const selectedList = attributeForm.selectedList;
    const unselectedList = attributeForm.unSelectedList;

    items.forEach(item => {
        moveAttributeFromSourceToDestination(selectedList, unselectedList, item);
    });

    filterAttributes(selectedList);
    filterAttributes(unselectedList);
}

const moveAttributeFromSourceToDestination = (source: AttributeFilterList, destination: AttributeFilterList,
    attribute: IAvailableAdditionAttribute) => {
    const indexInAll = source.allItems.findIndex(x => x.id === attribute.id);
    destination.allItems.push(attribute);
    source.allItems.splice(indexInAll, 1);
}

export const createAttributForms = (state: AdditionState, attributCategories: IAdditionAttributeCategory[]) => {
    attributCategories.forEach(attributCategory => {
        const isLocked = attributCategory.type === AdditionCategoryType.Blacklist ? true : false;
        const selectedCategoryIndex = state.actualData.additionEditor.categories.availableCategories.findIndex(x => x.id === attributCategory.availableAdditionAttributeCategory.id);
        const category = state.actualData.additionEditor.categories.availableCategories[selectedCategoryIndex];
        const attributeForm: AttributeForm = {
            isLocked: isLocked,
            selectedCategory: category,
            selectedList: {
                allItems: [],
                filteredItems: [],
                searchText: "",
            },
            unSelectedList: {
                allItems: [],
                filteredItems: [],
                searchText: "",
            },
        }

        state.actualData.additionEditor.categories.availableCategories.splice(selectedCategoryIndex, 1);

        fillAvailableAttributesToAttributeForm(attributeForm, state.loadedData.additionInitial.availableAttributeCategories);
        
        attributCategory.additionAttributeVersions.forEach(attribut => {
            moveAttributeFromSourceToDestination(attributeForm.unSelectedList, attributeForm.selectedList, attribut.availableAdditionAttribute);
        });
        filterAttributes(attributeForm.selectedList);
        filterAttributes(attributeForm.unSelectedList);
        state.actualData.additionEditor.categories.attributeForms.push(attributeForm);
    });
}

export const moveGussetArticleTypeFromUnselectedToSelected = (state: AdditionState,
    selection: IIndexedIds) => {
    const index = selection.index;
    const additionGusset = state.actualData.additionEditor.addition.additionGussets[index];
    const selectedList = additionGusset.additionGussetArticleTypes.selectedList;
    const unselectedList = additionGusset.additionGussetArticleTypes.unSelectedList;

    selection.ids.forEach(id => {
        moveGussetArticleTypeFromSourceToDestination(unselectedList, selectedList, id);
    });

    filterArticleTypes(selectedList);
    filterArticleTypes(unselectedList);
}

export const moveGussetArticleTypeFromSelectedToUnselected = (state: AdditionState,
    selection: IIndexedIds) => {
    const index = selection.index;
    const additionGusset = state.actualData.additionEditor.addition.additionGussets[index];
    const selectedList = additionGusset.additionGussetArticleTypes.selectedList;
    const unselectedList = additionGusset.additionGussetArticleTypes.unSelectedList;

    selection.ids.forEach(id => {
        moveGussetArticleTypeFromSourceToDestination(selectedList, unselectedList, id);
    });


    filterArticleTypes(selectedList);
    filterArticleTypes(unselectedList)
}

export const moveGussetArticleTypeFromSourceToDestination = (source: ArticleTypeFilterList,
    destination: ArticleTypeFilterList, articleType: number) => {
    const indexInAll = source.allItems.findIndex(x => x.id === articleType);
    destination.allItems.push(source.allItems[indexInAll]);
    source.allItems.splice(indexInAll, 1);
}

export const moveGussetQualityFromUnselectedToSelected = (state: AdditionState, selection: IIndexedIds) => {
    const index = selection.index;
    const additionGusset = state.actualData.additionEditor.addition.additionGussets[index];
    const selectedList = additionGusset.additionGussetQualities.selectedList;
    const unselectedList = additionGusset.additionGussetQualities.unSelectedList;

    selection.ids.forEach(id => {
        moveGussetQualitiesFromSourceToDestination(unselectedList, selectedList, id);
    });

    filterQualities(selectedList);
    filterQualities(unselectedList);
}

export const moveGussetQualityFromSelectedToUnselected = (state: AdditionState, selection: IIndexedIds) => {
    const index = selection.index;
    const additionGusset = state.actualData.additionEditor.addition.additionGussets[index];
    const selectedList = additionGusset.additionGussetQualities.selectedList;
    const unselectedList = additionGusset.additionGussetQualities.unSelectedList;

    selection.ids.forEach(id => {
        moveGussetQualitiesFromSourceToDestination(selectedList, unselectedList, id);
    });

    filterQualities(selectedList);
    filterQualities(unselectedList)
}

export const moveGussetQualitiesFromSourceToDestination = (source: QualityFilterList, destination: QualityFilterList,
    quality: number) => {
    const indexInAll = source.allItems.findIndex(x => x.id === quality);
    destination.allItems.push(source.allItems[indexInAll]);
    source.allItems.splice(indexInAll, 1);
}

export const sortMassPointCriteria = (mp1: IGussetMassPointViewModel, mp2: IGussetMassPointViewModel) => {
    return mp1.displayName.localeCompare(mp2.displayName);
}

export const distinctMassPointCriteria = (value: IGussetMassPointViewModel, currentIndex: number,
    arr: IGussetMassPointViewModel[]) => {
    const indexOfFirstOccurrence = arr.findIndex(mp =>
        mp.name === value.name
        && mp.type === value.type
        && mp.bodyArea.id === value.bodyArea.id)
    return currentIndex === indexOfFirstOccurrence;
}

export const convertPlaceholderValuesToIntegers = (additionEditor: IAdditionEditor) => {
    switch (additionEditor.addition.placeholder.placeholderTemplate.value) {
        case AdditionPlaceHolderTypeEnum.RangeInput:
            additionEditor.addition.placeholder.defaultConfig.rangePlaceholderVersion.minimalValue = Math.trunc(additionEditor.addition.placeholder.defaultConfig.rangePlaceholderVersion.minimalValue);
            additionEditor.addition.placeholder.defaultConfig.rangePlaceholderVersion.maximalValue = Math.trunc(additionEditor.addition.placeholder.defaultConfig.rangePlaceholderVersion.maximalValue);
            additionEditor.addition.placeholder.qualityConfigs.forEach(qC => {
                qC.config.rangePlaceholderVersion.minimalValue = Math.trunc(qC.config.rangePlaceholderVersion.minimalValue);
                qC.config.rangePlaceholderVersion.maximalValue = Math.trunc(qC.config.rangePlaceholderVersion.maximalValue);
            })
            break;
        case AdditionPlaceHolderTypeEnum.TwoRangeInput:
            additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.firstMinimalValue = Math.trunc(additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.firstMinimalValue);
            additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.firstMaximalValue = Math.trunc(additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.firstMaximalValue);
            additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.secondMinimalValue = Math.trunc(additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.secondMinimalValue);
            additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.secondMaximalValue = Math.trunc(additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.secondMaximalValue);
            additionEditor.addition.placeholder.qualityConfigs.forEach(qC => {
                qC.config.twoRangePlaceholderVersion.firstMinimalValue = Math.trunc(qC.config.twoRangePlaceholderVersion.firstMinimalValue);
                qC.config.twoRangePlaceholderVersion.firstMaximalValue = Math.trunc(qC.config.twoRangePlaceholderVersion.firstMaximalValue);
                qC.config.twoRangePlaceholderVersion.secondMinimalValue = Math.trunc(qC.config.twoRangePlaceholderVersion.secondMinimalValue);
                qC.config.twoRangePlaceholderVersion.secondMaximalValue = Math.trunc(qC.config.twoRangePlaceholderVersion.secondMaximalValue);
            })
            break;
        default:
            break;
    }
}

export const extractAdditionDefinition = (state: AdditionState, additionDefinition: IAdditionBaseDataResult) => {
    if (additionDefinition.success === true && additionDefinition.result != null) {
        state.actualData.showERPDataLoader = false;
        state.errorData.additionDefinitionError = null;
        state.actualData.additionEditor.addition.option = additionDefinition.result.option;
        state.actualData.additionEditor.addition.code = additionDefinition.result.code;
        if (additionDefinition.hasCalingaDescription) {
            state.actualData.additionEditor.addition.description = additionDefinition.calingaDescription;
        } else {
            state.actualData.additionEditor.addition.description = additionDefinition.result.description;
        }
        state.actualData.additionEditor.addition.descriptionLong = additionDefinition.descriptionLong;
        state.actualData.additionEditor.addition.hasCalingaDescription = additionDefinition.hasCalingaDescription;
        state.actualData.additionEditor.addition.baseDescription = additionDefinition.result.description;
        state.actualData.additionEditor.addition.quantityCode = additionDefinition.result.quantityCode;
        state.actualData.additionEditor.addition.quantityText = additionDefinition.result.quantityText;
        state.actualData.additionEditor.addition.side = state.loadedData.additionInitial.sides.find(x => x.value === additionDefinition.result.side);
        state.actualData.additionEditor.selectedLockCountry = state.loadedData.additionInitial.lockCountryOptions.find(x => x.value === false);
        state.actualData.additionEditor.addition.category = state.loadedData.additionInitial.categories.find(x => x.categoryKey === "Default_Category");
        state.actualData.additionEditor.addition.placeholder = toEmptyPlaceholderViewModel(state);
        state.actualData.additionEditor.selectedPositioningOption = state.loadedData.additionInitial.positioningOptions.find(x => x.value === false);
        state.actualData.additionEditor.addition.additionGussets = [];
    }
    else {
        state.actualData.showERPDataLoader = true;
        state.errorData.additionDefinitionError = { isVisible: true, text: "Die ERP-Artikelnummer existiert nicht." };
    }
}

const toEmptyPlaceholderViewModel = (state: AdditionState): IPlaceholderViewModel => {
    return {
        placeholderTemplate: state.loadedData.additionInitial.placeholderTypes.find(x => x.value === AdditionPlaceHolderTypeEnum.None),
        defaultConfig: getNewConfig(),
        qualityConfigs: [],
        twoRangePlaceholderIsSideSpecific: true,
        isInteger: false
    }
};

export const updateCanGetAddition = (state: AdditionState) => {
    state.query.getAdditionDefinition.canExecute = doesExist(state.actualData.optionName)
        && isNotEmpty(state.actualData.optionName)
}