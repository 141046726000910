import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import * as style from "./searchfield.style";

interface SearchFieldProps {
  onSearch: (searchText: string) => void;
  placeholder: string;
  isReadOnly?: boolean;
}

const SearchFieldComponent: React.FC<SearchFieldProps> = ({ onSearch, placeholder, isReadOnly: isReadonly }) => {
  const [searchText, setSearchText] = useState("");

  const [timerRef, setTimerRef] = useState<NodeJS.Timeout>(null);

  const onKeyDown = (event) => {
    clearTimeout(timerRef);
    setTimerRef(setTimeout(() => onSearch(event.target.value), 300));
  }

  const isActive = (searchText: string) => {
    return (searchText != null &&
      searchText != undefined &&
      searchText.length > 0 &&
      !isReadonly);
  }

  return (
    <div>
      <style.Box>
        <style.TextField
          disabled={isReadonly}
          onChange={(e) => setSearchText(e.currentTarget.value)}
          onKeyDown={onKeyDown}
          type="text"
          placeholder={placeholder}
        ></style.TextField>
        <style.SearchButton
          onClick={() => {
            if (!isReadonly) {
              clearTimeout(timerRef);
              onSearch(searchText);
            }
          }}
        >
          <style.SearchInfoIcon
            active={isActive(searchText)}
          >
            Suchen <SearchIcon />
          </style.SearchInfoIcon>
        </style.SearchButton>
      </style.Box>
    </div>
  );
};

export default SearchFieldComponent;
