import SearchFieldComponent from 'shared/components/search/searchfield.component';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { IconButton, Grid } from '@mui/material';
import * as style from "./selectionList.style";
import { ISelectable } from "./models/selectable";
import colors from "shared/style/colors";
import { PaperItem } from 'shared/style/paper';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { MoveAllContainerStyled } from './selectionList.style';
import { doesExist, isNullOrWhitespace } from 'services/validation.service';
import { ToggleButtonComponent } from '../buttons/toggle_button.component';
import { ISelectionList } from './models/selectionList';
import { LockTypeEnum } from './models/locktype.enum';

interface SelectionListComponentProps {
    onFilterSelected: (searchText: string) => void;
    onFilterUnselected: (searchText: string) => void;
    title?: string;
    entityName: string;
    selectionList: ISelectionList
    showSelectedAllIcon?: boolean;
    selectEntities: (ids: number[]) => void,
    unselectEntities: (ids: number[]) => void, 
    allow?: () => void,
    deny?: () => void,
}

export const SelectionListComponent: React.FC<SelectionListComponentProps> = ({
    onFilterSelected,
    onFilterUnselected,
    title,
    entityName,
    selectionList,
    showSelectedAllIcon,
    selectEntities,
    unselectEntities,
    allow,
    deny
}) => {
    if (showSelectedAllIcon == undefined) {
        showSelectedAllIcon = false;
    }

    const renderUnselectedList = selectionList.unSelectedList.filteredItems.map((item, index) => {
        const isEvenIndex = index % 2 === 0;
        return (
            <div key={index} style={isEvenIndex ? { backgroundColor: colors.mediLightGray } : {}}>
                <IconButton style={{ outline: "none" }} onClick={() => selectEntities([item.id])} >
                    <KeyboardArrowLeftIcon />
                </IconButton>
                {" "}
                {item.displayName}
            </div >
        )
    });

    const selectAll = () => {
        selectEntities(selectionList.unSelectedList.filteredItems.map(x => x.id));
    }

    const unSelectAll = () => {
        unselectEntities(selectionList.selectedList.filteredItems.map(x => x.id));
    }

    const renderSelectedList = selectionList.selectedList.filteredItems.map((item, index) => {
        const isEvenIndex = index % 2 === 0;
        return (
            <div key={index} style={isEvenIndex ? { backgroundColor: colors.mediLightGray } : {}}>
                <IconButton style={{ outline: "none" }} onClick={() => unselectEntities([item.id])}>
                    <KeyboardArrowRightIcon />
                </IconButton>
                {" "}
                {item.displayName}
            </div>
        )
    });

    const displayDummyList = [{ displayName: "Alle" }];
    const renderDummySelectedList = displayDummyList.map((item, index) => {
        const isEvenIndex = index % 2 === 0;
        return (
            <div key={index} style={isEvenIndex ? { backgroundColor: colors.mediLightGray } : {}}>
                <IconButton style={{ outline: "none" }} disabled>
                    <AllInclusiveIcon />
                </IconButton>
                {" "}
                {item.displayName}
            </div >
        );
    });

    return (
        <>
            {
                !isNullOrWhitespace(title) &&
                <style.Header>
                    <style.Headline>{title}</style.Headline>
                    { allow && deny && <div>
                        <ToggleButtonComponent
                            enabledText="erlauben"
                            disabledText="verbieten"
                            isEnabled={selectionList.lockType === LockTypeEnum.Allow}
                            onDisable={() => deny()}
                            onEnable={() => allow()}
                        />
                    </div>}
                </style.Header>
            }
            <PaperItem>
                <Grid item container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    wrap="nowrap">
                    <Grid item md={6}>
                        <Grid
                            container
                            direction="column"
                            spacing={2}>
                            <Grid item>
                                <strong>Ausgewählte {entityName}</strong>
                            </Grid>
                            <Grid item>
                                <SearchFieldComponent
                                    onSearch={onFilterSelected}
                                    placeholder="In Ausgewählten suchen" />
                            </Grid>
                            <Grid item>
                                <style.ListWrapper>
                                    {(selectionList.selectedList.filteredItems.length > 0 || !showSelectedAllIcon) ?
                                        renderSelectedList : renderDummySelectedList}
                                </style.ListWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <MoveAllContainerStyled>
                            <IconButton style={{ outline: "none" }} onClick={() => selectAll()}>
                                <KeyboardDoubleArrowLeft />
                            </IconButton>
                            <IconButton style={{ outline: "none" }} onClick={() => unSelectAll()}>
                                <KeyboardDoubleArrowRight />
                            </IconButton>
                        </MoveAllContainerStyled>
                    </Grid>
                    <Grid item md={6}>
                        <Grid
                            container
                            direction="column"
                            spacing={2}>
                            <Grid item>
                                <strong>Verfügbare {entityName}</strong>
                            </Grid>
                            <Grid item>
                                <SearchFieldComponent
                                    onSearch={onFilterUnselected}
                                    placeholder="In Verfügbaren suchen" />
                            </Grid>
                            <Grid item>
                                <style.ListWrapper>
                                    {renderUnselectedList}
                                </style.ListWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PaperItem>
        </>
    )
}