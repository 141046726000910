import AddIcon from "@mui/icons-material/Add";
import { PrimaryButtonComponent } from "../../shared/shared";
import { FlexBox, Left, Right } from "../../shared/style/alignment";
import SearchFieldComponent from "../../shared/components/search/searchfield.component";
import { LoadingIndicatorComponent } from '../../shared/components/loading_indicator/loading_indicator.component';
import { AppDispatch } from "app/redux_store";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { AdditionDeleteDialogComponent } from './components/addition_delete_dialog.component';
import {
  setSearchFilter,
  publishAdditionCompleted,
  resetState,
  unpublishAdditionCompleted,
  deleteAddition,
  resetDeleteAddition,
  setGridFilters,
} from "./redux/addition_overview.slice";
import { createNewAddition, getAdditions, togglePublishAddition, updateAddition } from './redux/addition_overview.thunks';
import * as style from "./addition_overview.style";
import { useNavigate } from "react-router-dom";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { getGridColumns, sortByCodeModel } from "./addition_overview.helper";

export const AdditionOverviewComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const state = useAppSelector((state) => state.addition_overview);

  useEffect(() => {
    dispatch(getAdditions());
    return () => {
      dispatch(resetState());
    }
  }, [dispatch]);

  if (state.query.getAdditions.status === "error") {
    enqueueSnackbar(state.query.getAdditions.message, { variant: "error" });
  }

  if (state.query.getAdditions.status !== "success") {
    return <LoadingIndicatorComponent />;
  }

  if (state.command.publishAddition.status === "success") {
    enqueueSnackbar("Zusatz erfolgreich veröffentlicht", { variant: "success" });
    dispatch(publishAdditionCompleted())
  }

  if (state.command.unpublishAddition.status === "success") {
    enqueueSnackbar("Veröffentlichung erfolgreich aufgehoben", { variant: "success" });
    dispatch(unpublishAdditionCompleted())
  }

  if (state.command.createNewAddition.status === "pending") {
    navigate(`/additions/addition/create`);
  }

  if (state.command.updateAddition.status === "success") {
    navigate(`/additions/addition/edit/${state.actualData.activeAddition.id}`);
  }

  if (state.command.confirmDeleteAddition.status === "error") {
    enqueueSnackbar(state.command.confirmDeleteAddition.message, { variant: "error" });
    dispatch(resetDeleteAddition());
  }

  if (state.command.confirmDeleteAddition.status === "success") {
    enqueueSnackbar("Zusatz erfolgreich gelöscht", { variant: "success" });
    dispatch(resetDeleteAddition());
  }

  const isLoading = state.command.publishAddition.status === "pending"
    || state.command.unpublishAddition.status === "pending";

  const additionsAvailable = state.loadedData.additions?.length > 0;

  return (
    <div>
      {state.actualData.showDeleteDialog
        && <AdditionDeleteDialogComponent />
      }
      <FlexBox>
        {
          additionsAvailable &&
          <Left>
            <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))}
              placeholder="In Zusätzen suchen ..." />
          </Left>
        }
        <Right>
          <PrimaryButtonComponent onClick={() => dispatch(createNewAddition())}>
            <AddIcon /> Neuen Zusatz erstellen
          </PrimaryButtonComponent>
        </Right>
      </FlexBox>
      <style.TableContainer>
        {
          !additionsAvailable &&
          <div>Keine Zusätze verfügbar</div>
        }
        {
          additionsAvailable &&
          <OverviewDatagrid
            isLoading={isLoading}
            columns={getGridColumns(
              (addition) => dispatch(updateAddition(addition)),
              (addition) => dispatch(deleteAddition(addition)),
              (addition) => dispatch(togglePublishAddition(addition))
            )}
            rows={state.actualData.additions}
            loadedRowsCount={state.loadedData.additions.length}
            sortModel={sortByCodeModel}
            onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
            onDoubleClickRow={addition => dispatch(updateAddition(addition))}
          />
        }
      </style.TableContainer>
    </div>
  );
};