import { DialogComponent } from '../../../shared/components/dialogs/dialog.component';
import { cancelDeleteAddition } from '../redux/addition_overview.slice';
import { AppDispatch } from '../../../app/redux_store';
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { confirmDeleteAddition } from '../redux/addition_overview.thunks';

export const AdditionDeleteDialogComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.addition_overview);
    const addition = state.actualData.activeAddition;
    const additionText = `${addition.option} ${addition.description}`;

    return (<>
        <DialogComponent
            title={"Zusatz löschen?"}
            isOpen={true}
            options={[
                { title: "Abbrechen", handleOption: () => { dispatch(cancelDeleteAddition()) } },
                { title: "Löschen", handleOption: () => { dispatch(confirmDeleteAddition()) } }
            ]}
            onClose={() => { }}>
            <div>
                Möchten Sie den Zusatz {additionText} löschen?
            </div>
        </DialogComponent>
    </>);
}