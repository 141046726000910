import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/redux_store';
import { handleResponseErrors } from '../../../../shared/networking/error_handling';
import { ProductionInstructionService } from 'services/production_instruction.service';

export const saveProductionInstruction = createAsyncThunk('production-instruction/create/saveProductionInstruction', async (_, {
    getState,
    dispatch
}) => {
    const state = getState() as RootState;
    var data = state.production_instruction_create.actualData.productionInstructionEditor;
    const response = await ProductionInstructionService.create({
        symbol: data.symbol,
        calingaKey: data.calingaKey.key,
        isFreeTextEnabled: data.isFreeTextEnabled.value,
        isDocumentsEnabled: data.isDocumentsEnabled.value,
        isAutomaticProductionEnabled: data.isAutomaticProductionEnabled.value
    });
    handleResponseErrors(response, "Produktionsanweisung");
    return response;
})

export const getProductionInstructionCalingaKeys = createAsyncThunk('production-instruction/create/getCalingaKeys', async (thunkApi) => {
    const response = await ProductionInstructionService.getCalingaKeys();
    handleResponseErrors(response, "Produktionsanweisung");
    return response;
})

export const cancelSave = createAsyncThunk('production-instruction/create/cancelSave', async (_) => {
})