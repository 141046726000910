import { Grid } from '@mui/material';
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style'
import { EditorDescriptionComponent } from 'shared/components/editors/editor_description/editor_description.style'
import { DropdownComponent, RadioBoxAdvancedComponent, TextBoxComponent } from "shared/shared";
import { SelectCountriesComponent } from './addition_selectCountries.component';
import { AdditionCompressionClassComponent } from '../compressionclass/addition_compresssionclass.component';
import { SelectionListComponent } from 'shared/components/selectionComponent/selectionList.component';
import { MarginBottom } from '../tab/addition_tab.style';
import { IAdditionInitial, IAdditionEditor, IAdditionEditorAction } from 'models/additions/addition_editor.model';

interface ServiceDataComponentProps {
    additionInital: IAdditionInitial,
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction,
}

export const ServiceDataComponent: React.FC<ServiceDataComponentProps> = (
    {
        additionInital,
        additionEditor,
        additionEditorActions,
    }) => {
    return (<Grid container
        spacing={3}
        direction="column"
        justifyContent="space-between">
        <Grid item md={6}>
            <EditorSectionHeaderComponent>medi ERP HQ</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>Betrifft die Verwendung dieses Zusatzes in Kostenvoranschlags- und Auftragsprozessen.</EditorDescriptionComponent>
            <RadioBoxAdvancedComponent name="Auftragsposition anlegen"
                data={additionInital.createOrderPositionOptions}
                displayedProperties={["text"]}
                onSelect={additionEditorActions.updateCreateOrderPosition}
                isRequired={false}
                selectedItem={additionEditor.addition.createOrderPosition}
            />
            <RadioBoxAdvancedComponent name="Auftragsposition berechnen"
                data={additionInital.calculateOrderPosition}
                displayedProperties={["text"]}
                onSelect={additionEditorActions.updateCalculateOrderPosition}
                isRequired={false}
                selectedItem={additionEditor.addition.calculateOrderPosition}
            />
            <RadioBoxAdvancedComponent name="Rezeptvorschlag"
                data={additionInital.isRecipeValidOptions}
                description="Legt fest, ob der Zusatz für den Rezeptvorschlag im e-shop verwendet werden kann."
                displayedProperties={["text"]}
                onSelect={additionEditorActions.updateIsRecipeValid}
                isRequired={false}
                selectedItem={additionEditor.addition.isRecipeValid}
            />
        </Grid>
        <Grid container item direction="column" md={12}>
            <Grid item md={6}>
                <EditorSectionHeaderComponent>Maß-Service Global</EditorSectionHeaderComponent>
                <RadioBoxAdvancedComponent name="Bestellmanagement"
                    description="Legt fest, von welcher Benutzergruppe der Zusatz bestellt werden kann."
                    data={additionInital.orderManagementOptions}
                    displayedProperties={["text"]}
                    onSelect={additionEditorActions.updateOrderManagement}
                    isRequired={false}
                    selectedItem={additionEditor.addition.orderManagement}
                />
                <RadioBoxAdvancedComponent name="Wiederbestellung"
                    description="Kann der Zusatz bei Wiederbestellung automatisch zum Auftrag hinzugefügt und erneut produziert werden?"
                    data={additionInital.reorderOptions}
                    displayedProperties={["text"]}
                    onSelect={additionEditorActions.updateReorder}
                    isRequired={false}
                    selectedItem={additionEditor.addition.reOrder}
                />
                <RadioBoxAdvancedComponent name="Ländersperre"
                    description='Darf der Zusatz nur in bestimmten Ländern verwendet/nicht verwendet werden? Bei "Nein" ist der Zusatz international und ohne Einschränkung verfügbar'
                    data={additionInital.lockCountryOptions}
                    displayedProperties={["text"]}
                    onSelect={additionEditorActions.updateLockCountry}
                    isRequired={false}
                    selectedItem={additionEditor.selectedLockCountry}
                />
            </Grid>
            {additionEditor.selectedLockCountry?.value ?
                <SelectCountriesComponent
                    unselectedCountries={additionEditor.selectedCountries.unSelectedList.filteredItems}
                    selectedCountries={additionEditor.selectedCountries.selectedList.filteredItems}
                    onFilterSelectedCountry={additionEditorActions.filterSelectedCountries}
                    onFilterUnselectedCountry={additionEditorActions.filterUnselectedCountries}
                    selectCountries={additionEditorActions.selectCountries}
                    unselectCountries={additionEditorActions.unselectCountries}
                    allowCountry={additionEditorActions.allowCountry}
                    denyCountry={additionEditorActions.denyCountry}
                    countryLockType={additionEditor.addition.countryLock.countryLockType}
                />
                : <></>
            }
            <Grid item md={6}>
                <RadioBoxAdvancedComponent name="Positionierung des Zusatz"
                    description='Muss der Zusatz vom Kunden oder Erfasser manuell positioniert werden'
                    data={additionInital.positioningOptions}
                    displayedProperties={["text"]}
                    onSelect={additionEditorActions.updatePositioningOptions}
                    isRequired={false}
                    selectedItem={additionEditor.selectedPositioningOption}
                    disabled={additionEditor.selectedCharacteristicCategoryOption?.value}
                />
            </Grid>
            {
                additionEditor.selectedPositioningOption?.value &&
                <Grid item md={6}>
                    <MarginBottom>
                        <TextBoxComponent
                            name="Positionskürzel"
                            value={additionEditor.addition.positionCode}
                            onChange={(value) => additionEditorActions.updatePositionCode(value)}
                            maxLength={3}
                        />
                    </MarginBottom>
                </Grid>
            }
            {additionEditor.selectedPositioningOption?.value ?
                <Grid item md={9}>
                    <SelectionListComponent
                        title="Positionskategorien zuweisen"
                        entityName="Positionskategorien"
                        onFilterSelected={(searchText) => additionEditorActions.filterSelectedPositionCategories(searchText)}
                        onFilterUnselected={(searchText) => additionEditorActions.filterUnselectedPositionCategories(searchText)}
                        selectionList={additionEditor.positionCategorySelection}
                        selectEntities={(ids) => additionEditorActions.selectPositionCategories(ids)}
                        unselectEntities={(ids) => additionEditorActions.unselectPositionCategories(ids)}
                    />
                </Grid>
                : <></>
            }
            <Grid item md={6}>
                <RadioBoxAdvancedComponent name="Ausprägung des Zusatz"
                    description='Muss die Ausprägung des Zusatzes manuell vom Kunden oder Erfasser eingestellt werden'
                    data={additionInital.characteristicCategoryOptions}
                    displayedProperties={["text"]}
                    onSelect={additionEditorActions.updateCharacteristicCategoryOptions}
                    isRequired={false}
                    selectedItem={additionEditor.selectedCharacteristicCategoryOption}
                    disabled={additionEditor.selectedPositioningOption?.value}
                />
            </Grid>
            {additionEditor.selectedCharacteristicCategoryOption?.value ?
                <Grid item md={9}>
                    {
                        <DropdownComponent
                            name="Ausprägungstyp"
                            data={additionInital.characteristicCategories}
                            isLoading={false}
                            isRequired={false}
                            onSelect={(characteristicCategory) => additionEditorActions.characteristicCategoryActions.selectCharacteristicCategory(characteristicCategory.id)}
                            displayedProperties={["name"]}
                            selectedValue={additionInital.characteristicCategories.find(x => x.id == additionEditor.selectedcharacteristicCategoryId)}
                        />
                    }
                </Grid>
                : <></>
            }
        </Grid>

        <Grid item md={6}>
            <EditorSectionHeaderComponent>medi Produktion HQ</EditorSectionHeaderComponent>
            <RadioBoxAdvancedComponent name="Automatische Produktion"
                description="Findet ein automatischer Produktionsbeginn statt?"
                data={additionInital.automatedProductions}
                displayedProperties={["text"]}
                onSelect={additionEditorActions.updateAutomatedProduction}
                isRequired={false}
                selectedItem={additionEditor.addition.automaticProduction}
            />
            <RadioBoxAdvancedComponent name="Druckbereich"
                description="Legt fest, wo die Information auf dem Auftragszettel gedruckt wird."
                data={additionInital.printAreas}
                displayedProperties={["text"]}
                onSelect={additionEditorActions.updatePrintArea}
                isRequired={false}
                selectedItem={additionEditor.addition.printArea}
            />
            <RadioBoxAdvancedComponent name="Vollgestrickt"
                description="Wird der Zusatz als Vollstrick produziert?"
                data={additionInital.isFullyKnitted}
                displayedProperties={["text"]}
                onSelect={additionEditorActions.updateFullyKnitted}
                isRequired={false}
                selectedItem={additionEditor.addition.isFullyKnitted}
            />
            <AdditionCompressionClassComponent
                data={additionInital.compressionClass}
                selectedItem={additionEditor.addition.compressionClass}
                onSelect={additionEditorActions.updateCompressionClass} />
        </Grid>
    </Grid>
    );
};