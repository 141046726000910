import { IconButton, Grid } from '@mui/material';
import { IQualitySelectionViewModel } from 'models/additions/addition_editor.model';
import SearchFieldComponent from 'shared/components/search/searchfield.component';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import * as style from "./addition_validity_item_selection.style";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { PaperItem } from 'shared/style/paper';
import colors from 'shared/style/colors';
import { doesExist } from 'services/validation.service';
import IAvailableQuality from 'models/available_basedata/available_quality';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { MoveAllContainerStyled } from 'shared/components/selectionComponent/selectionList.style';
import { LockTypeEnum } from 'shared/components/selectionComponent/models/locktype.enum';
import { ToggleButtonComponent } from 'shared/components/buttons/toggle_button.component';

interface SelectionQualityComponentProps {
    qualitySelection: IQualitySelectionViewModel,
    onFilterUnselected: (searchText: string) => {};
    onFilterSelected: (searchText: string) => {};
    unselect: (qualities: IAvailableQuality[]) => {};
    select: (qualities: IAvailableQuality[]) => {};
    allow?: () => {},
    deny?: () => {},
    showSelectedAllIcon?: boolean
    isReadOnly?: boolean,
}

export const SelectionQualityComponent: React.FC<SelectionQualityComponentProps> = ({
    qualitySelection,
    onFilterUnselected,
    onFilterSelected,
    unselect,
    select,
    allow,
    deny,
    showSelectedAllIcon,
    isReadOnly
}) => {
    const unselectedQualityList = qualitySelection.unSelectedList.filteredItems.map((item, index) => {
        const isEvenIndex = index % 2 === 0;
        return (
            <div key={index} style={isEvenIndex ? { backgroundColor: colors.mediLightGray } : {}}>
                <IconButton disabled={isReadOnly} style={{ outline: "none" }} onClick={() => select([item])} >
                    <KeyboardArrowLeftIcon />
                </IconButton>
                {" "}
                {item.erpId + " " + item.name}
            </div >
        )
    });

    const selectAll = () => {
        select(qualitySelection.unSelectedList.filteredItems);
    }

    const unSelectAll = () => {
        unselect(qualitySelection.selectedList.filteredItems);
    }

    const displayDummy = showSelectedAllIcon ? [{ displayName: "Alle" }] : [];

    const selectedQualityList = qualitySelection.selectedList.allItems.length > 0 ? qualitySelection.selectedList.filteredItems.map((item, index) => {
        const isEvenIndex = index % 2 === 0;
        return (
            <div key={index} style={isEvenIndex ? { backgroundColor: colors.mediLightGray } : {}}>
                <IconButton disabled={isReadOnly} style={{ outline: "none" }} onClick={() => unselect([item])}>
                    <KeyboardArrowRightIcon />
                </IconButton>
                {" "}
                {item.erpId + " " + item.name}
            </div>
        )
    }) :
        displayDummy.map((item, index) => {
            return (
                <div key={index}>
                    <IconButton disabled={isReadOnly} style={{ outline: "none" }}>
                        <AllInclusiveIcon />
                    </IconButton>
                    {" "}
                    {item.displayName}
                </div>
            );
        });

    const renderLocked = () => {
        return <style.LockedHeader>
            <style.LockedHeadline>Qualität</style.LockedHeadline>
            {
                doesExist(allow) && doesExist(deny) &&
                <style.ToggleWrapper>
                    <style.ToggleInfo>Verhalten in der Konfiguration</style.ToggleInfo>
                    <ToggleButtonComponent
                        enabledText="Erlauben"
                        disabledText="Verbieten"
                        isReadOnly={isReadOnly}
                        isEnabled={qualitySelection.lockType === LockTypeEnum.Allow}
                        onDisable={() => deny()}
                        onEnable={() => allow()}
                    />
                </style.ToggleWrapper>
            }
        </style.LockedHeader>
    }

    return (
        <Grid style={{ opacity: isReadOnly ? "0.5" : "1" }} item md={12}>
            {renderLocked()}
            <PaperItem>
                <Grid item container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    wrap="nowrap">
                    <Grid item md={6}>
                        <Grid
                            container
                            direction="column"
                            spacing={2}>
                            <Grid item>
                                <strong>Ausgewählte Qualitäten</strong>
                            </Grid>
                            <Grid item>
                                <SearchFieldComponent
                                    isReadOnly={isReadOnly}
                                    onSearch={(searchText) => onFilterSelected(searchText)}
                                    placeholder="In Ausgewählten suchen" />
                            </Grid>
                            <Grid item>
                                <style.ListWrapper>
                                    {selectedQualityList}
                                </style.ListWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid item>
                            <MoveAllContainerStyled>
                                <IconButton style={{ outline: "none" }} onClick={() => selectAll()}>
                                    <KeyboardDoubleArrowLeft />
                                </IconButton>
                                <IconButton style={{ outline: "none" }} onClick={() => unSelectAll()}>
                                    <KeyboardDoubleArrowRight />
                                </IconButton>
                            </MoveAllContainerStyled>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid
                            container
                            direction="column"
                            spacing={2}>
                            <Grid item>
                                <strong>Verfügbare Qualitäten</strong>
                            </Grid>
                            <Grid item>
                                <SearchFieldComponent
                                    isReadOnly={isReadOnly}
                                    onSearch={(searchText) => onFilterUnselected(searchText)}
                                    placeholder="In Verfügbaren suchen" />
                            </Grid>
                            <Grid item>
                                <style.ListWrapper>
                                    {unselectedQualityList}
                                </style.ListWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PaperItem>
        </Grid>
    );
}