import React from "react";
import { Grid, Typography } from "@mui/material";
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style';
import { DropdownComponent } from 'shared/components/form_controls/dropdown/dropdown.component';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import theme from 'shared/style/theme';
import Input from "@mui/material/Input";
import { RadioBoxAdvancedComponent } from "shared/shared";
import { IProductionInstructionEditor, IProductionInstructionEditorAction } from "./redux/production_instruction_editor.model";
import { IProductionInstructionCalingaKey } from "models/production_instructions/production_instruction_calinga_key";
import { getConfig } from "services/config.service";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { CalingaLinkComponent } from "shared/components/editors/calinga_link/calinga_link.component";
import { YesNoOptions } from "../../../models/boolean_radio_box.options";

interface ProductionInstructionEditorComponentProps {
    calingaKeys: IProductionInstructionCalingaKey[],
    productionInstructionEditor: IProductionInstructionEditor,
    productionInstructionEditorActions: IProductionInstructionEditorAction,
    canSave: boolean,
    editMode: boolean
}

export const ProductionInstructionEditorComponent: React.FC<ProductionInstructionEditorComponentProps> = ({
    calingaKeys, productionInstructionEditor, productionInstructionEditorActions, canSave, editMode

}) => {
    const header = !editMode ? "Neue Produktionsanweisung erstellen" : "Produktionsanweisung editieren";

    return (<>
        <Grid item
              direction="column"
              container
              spacing={3}>
            <Grid container
                  direction="column"
                  item md={6}>
                <EditorSectionHeaderComponent>{header}</EditorSectionHeaderComponent>
            </Grid>
            <Grid item md={3}>
                <FormControl
                    component="fieldset"
                    style={{ minWidth: theme.form.minWidth }}
                    required={true}>
                    <InputLabel htmlFor='anweisung'>Anweisungskürzel</InputLabel>
                    <Input
                        aria-describedby='anweisung_description'
                        id='anweisung'
                        autoFocus={false}
                        placeholder='z.B. ABC'
                        required={true}
                        disabled={false}
                        inputProps={{ maxLength: 3 }}
                        onChange={(event) => productionInstructionEditorActions.selectSymbol(event.currentTarget.value)}
                        value={productionInstructionEditor.symbol}
                    />
                </FormControl>
            </Grid>
            <Grid
                container
                item
                direction="row"
                justifyContent={'flex-start'}
                alignItems={'flex-end'}>
                <Grid item>
                    <DropdownComponent name="Anweisungstext"
                                       data={calingaKeys}
                                       isRequired={true}
                                       onSelect={productionInstructionEditorActions.selectProductionInstructionCalingaKey}
                                       displayedProperties={["key"]}
                                       selectedValue={productionInstructionEditor.calingaKey}
                    />
                </Grid>
                {productionInstructionEditor.calingaKey && <Grid item>
                    <Typography textAlign={'end'}>
                        {productionInstructionEditor.calingaKey.previewText}
                    </Typography>
                </Grid>}
            </Grid>
            <CalingaLinkComponent projectName={getConfig().calinga.project.productionInstructions}/>

            <Grid
                container
                item md={12} direction="row">
                <RadioBoxAdvancedComponent name="Freitexteingabe"
                                           description="Auftragserfasser/innen haben ein Textfeld zur Verfügung, um weitere von Hand einzugeben."
                                           data={YesNoOptions}
                                           displayedProperties={["text"]}
                                           onSelect={productionInstructionEditorActions.selectIsFreetextEnabled}
                                           isRequired={false}
                                           selectedItem={productionInstructionEditor.isFreeTextEnabled}
                />
            </Grid>
            <Grid
                container
                item md={12} direction="row">
                <RadioBoxAdvancedComponent name="Hinzufügen von Bildmaterial"
                                           description="Auftragserfasser/innen dürfen oder müssen Bildmaterial wie z.B. Fotos, Zeichnungen, ... hochladen und an die Produktionsanweisung anhängen."
                                           data={YesNoOptions}
                                           displayedProperties={["text"]}
                                           onSelect={productionInstructionEditorActions.selectIsDocumentEnabled}
                                           isRequired={false}
                                           selectedItem={productionInstructionEditor.isDocumentsEnabled}
                />
            </Grid>
            <Grid
                container
                item md={12} direction="row">
                <RadioBoxAdvancedComponent name="Automatische Produktion möglich"
                                           description="Findet ein automatischer Produktionsbeginn statt?"
                                           data={YesNoOptions}
                                           displayedProperties={["text"]}
                                           onSelect={productionInstructionEditorActions.selectIsAutoProductionEnabled}
                                           isRequired={false}
                                           selectedItem={productionInstructionEditor.isAutomaticProductionEnabled}
                />
            </Grid>
            <EditorButtonComponent
                canExecute={canSave}
                save={productionInstructionEditorActions.saveProductionInstruction}
                cancelSave={productionInstructionEditorActions.cancelSave}
            />
        </Grid>
    </>)
};