import { IFilterList } from "./models/filterList";
import { LockTypeEnum } from "./models/locktype.enum";
import { ISelectable } from "./models/selectable";
import { ISelectionList } from "./models/selectionList";

export const moveItemFromSourceToDestination = (source: IFilterList, destination: IFilterList, itemToMove: any) => {
    const indexInAll = source.allItems.findIndex(x => x.id === itemToMove.id);
    destination.allItems.push(itemToMove);
    source.allItems.splice(indexInAll, 1);
}

export const filterSelectionList = (listToFilter: IFilterList) => {
    listToFilter.filteredItems = listToFilter.allItems
        .filter(m => matchesText(m, listToFilter.searchText))
        .sort(sortSelectableCriteria);
}

const matchesText = (m: ISelectable, searchText: string): boolean => {
    if (searchText === "") {
        return true;
    }
    const searchTextLowerCase = searchText.toLowerCase();
    return (m.displayName.toLowerCase().includes(searchTextLowerCase));
}

const sortSelectableCriteria = (mp1: ISelectable, mp2: ISelectable) => {
    return mp1.displayName.localeCompare(mp2.displayName);
}

export const moveFromUnselectedToSelected = (selectionList: ISelectionList, ids: number[]) => {
    const selectedList = selectionList.selectedList;
    const unselectedList = selectionList.unSelectedList;

    ids.forEach(id => {
        const itemToMove = unselectedList.allItems.find(x => x.id === id);
        moveItemFromSourceToDestination(unselectedList, selectedList, itemToMove);
    });

    filterSelectionList(selectedList);
    filterSelectionList(unselectedList);
}

export const moveFromSelectedToUnselected = (selectionList: ISelectionList, ids: number[]) => {
    const selectedList = selectionList.selectedList;
    const unselectedList = selectionList.unSelectedList;

    ids.forEach(id => {
        const itemToMove = selectedList.allItems.find(x => x.id === id);
        moveItemFromSourceToDestination(selectedList, unselectedList, itemToMove);
    });

    filterSelectionList(selectedList);
    filterSelectionList(unselectedList)
}

export const createSelectionList = <T extends {id?: number}>(items: T[], selectedIds: number[], getDisplayName: (T) => string): ISelectionList => {
    
    const selectedList = items
        .filter(x => selectedIds.some( y=> y === x.id ))
        .map(x => toISelectable<T>(x, getDisplayName));

    const unSelectedList = items
        .filter(x => !selectedIds.some( y=> y === x.id ))
        .map(x => toISelectable<T>(x, getDisplayName));
    
    const result: ISelectionList = {
        selectedList: {
            allItems: selectedList,
            filteredItems: [],
            searchText: ""
        },
        unSelectedList: {
            allItems: unSelectedList,
            filteredItems: [],
            searchText: ""
        }
    } 

    filterSelectionList(result.selectedList);
    filterSelectionList(result.unSelectedList);

    return result;
}

const toISelectable = <T extends {id?: number}>(item: T, getDisplayName: (T) => string ) => {
    return {
        id: item.id,
        displayName: getDisplayName(item)
    } as ISelectable
}

export const getEmptySelectionList = (): ISelectionList => {
    return {
        lockType: LockTypeEnum.Undefined,
        selectedList: {
            searchText: "",
            allItems: [],
            filteredItems: [],
        },
        unSelectedList: {
            searchText: "",
            allItems: [],
            filteredItems: [],
        }
    }
}