import { GridCellParams, GridFilterItem, GridColDef, getGridBooleanOperators, GridSortModel } from "@mui/x-data-grid-pro"
import IAdditionOverview from "models/additions/addition_overview"
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component"
import { CustomChipCellComponent } from "shared/components/datagrid/custom_chip_cell.component"
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators"
import { CustomTooltipHeader } from "shared/components/datagrid/custom_tooltip_header.component"
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component"
import { TooltipComponent } from "shared/components/tooltip/tooltip"

export const filterAdditionValue = (filterItem: GridFilterItem, filteredItems: IAdditionOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'option':
                filteredItems = filteredItems.filter(x => x.option.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'code':
                filteredItems = filteredItems.filter(x => x.code.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;   
            case 'description':
                filteredItems = filteredItems.filter(x => x.description.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;           
            case 'categoryKey':
                filteredItems = filteredItems.filter(x => x.categoryKey.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;          
            case 'additionScopeVersionsCount':
                filteredItems = filteredItems.filter(x => x.additionScopeVersionsCount.toString().toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;  
            case 'isPublished':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.isPublished.toString() === filterItem.value);
                break;                                                                                                      
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    editAddition: (addition: IAdditionOverview) => void,
    deleteAddition: (addition: IAdditionOverview) => void,
    togglePublishAddition: (addition: IAdditionOverview) => void,
    ): GridColDef[] => [
    {
        field: "menu",
        headerName: " ",
        width: 15,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => DatagridMenu({params: params, menuItemsData: 
            [
                {label: "Zusatz editieren", action: editAddition},
                {label: params.row.isPublished ? "Veröffentlichung aufheben" : "Zusatz veröffentlichen", action: togglePublishAddition},
                {label: "Zusatz löschen", action: deleteAddition}
            ]})
    },
    {
        field: "option",
        headerName: "ERP-Artikelnummer",
        disableColumnMenu: false,
        width: 140,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },  
    {
        field: "code",
        headerName: "Zusatzcode",
        disableColumnMenu: false,
        width: 100,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },  
    {
        field: "description",
        headerName: "Bezeichnung",
        disableColumnMenu: false,
        flex: 1,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },   
    {
        field: "categoryKey",
        headerName: "Zusatzkategorie",
        disableColumnMenu: false,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },  
    {
        field: "additionScopeVersionsCount",
        headerName: "Gültigkeitsbereiche",
        disableColumnMenu: false,
        width: 140,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators('number'),
        renderCell: (params) => CustomChipCellComponent({content: params.row.additionScopeVersionsCount?.toString()})
    }, 
    {
        field: "isPublished",
        headerName: "Veröffentlicht",
        disableColumnMenu: false,
        width: 80,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: getGridBooleanOperators(),
        renderHeader: () => CustomTooltipHeader({ shortText: "v", tooltip: "Veröffentlicht" }),
        renderCell: (params) => CustomBooleanCell({value: params.row.isPublished})
    }
]    

export const sortByCodeModel: GridSortModel = [
    {
        field: "code",
        sort: "asc",
    },
];