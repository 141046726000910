import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from '../../../../app/redux_store';
import { handleResponseErrors } from '../../../../shared/networking/error_handling';
import { ProductionInstructionService } from 'services/production_instruction.service';

export const editProductionInstruction = createAsyncThunk('production-instruction/edit/editProductionInstruction', async (_, {
    getState, dispatch
}) => {
    const state = getState() as RootState;
    var data = state.production_instruction_edit.actualData;
    const response = await ProductionInstructionService.update({
        productionInstructionId: data.id,
        symbol: data.productionInstructionEditor.symbol,
        calingaKey: data.productionInstructionEditor.calingaKey.key,
        isFreeTextEnabled: data.productionInstructionEditor.isFreeTextEnabled.value,
        isDocumentsEnabled: data.productionInstructionEditor.isDocumentsEnabled.value,
        isAutomaticProductionEnabled: data.productionInstructionEditor.isAutomaticProductionEnabled.value
    });
    handleResponseErrors(response, "Produktionsanweisung");
    return response;
})

export const loadInitialData = createAsyncThunk('production-instruction/edit/loadInitial', async (id: string, thunkAPI) => {

    await thunkAPI.dispatch(getProductionInstructionCalingaKeys());
    const response = await ProductionInstructionService.getById(id);
    handleResponseErrors(response, "Produktionsanweisung");
    return response;

})

export const getProductionInstructionCalingaKeys = createAsyncThunk('production-instruction/edit/getCalingaKeys', async (thunkApi) => {
    const response = await ProductionInstructionService.getCalingaKeys();
    handleResponseErrors(response, "Produktionsanweisung");
    return response;
})