import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { LoadingOverlayComponent, LoadingOverlayContainer } from '../../shared/components/loading_overlay/loading_overlay.component';
import {
    resetState,
    updateCategoryKey,
    updateCategoryText,
    saveAdditionCategoryErrorCompleted,
    saveAdditionCategoryCompleted,
} from './redux/addition_category_create.slice';
import { useSnackbar } from "notistack";
import { Grid } from "@mui/material";
import { cancelSave, saveAdditionCategory } from './redux/addition_category_create.thunks';
import { TextBoxComponent } from "shared/shared";
import { useNavigate } from "react-router-dom";
import { getConfig } from "services/config.service";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { CalingaLinkComponent } from "shared/components/editors/calinga_link/calinga_link.component";

export const AdditionCategoryCreateComponent = () => {

    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.addition_category_create);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.command.saveAdditionCategory.status === "error") {
        enqueueSnackbar(state.command.saveAdditionCategory.message, { variant: "error" });
        dispatch(saveAdditionCategoryErrorCompleted());
    }

    if (state.command.saveAdditionCategory.status === "warning") {
        enqueueSnackbar(state.command.saveAdditionCategory.message, { variant: "warning" });
        dispatch(saveAdditionCategoryErrorCompleted());
    }

    if (state.command.saveAdditionCategory.status === "success") {
        enqueueSnackbar("Zusatzkategorie gespeichert", { variant: "success" });
        dispatch(saveAdditionCategoryCompleted());
        navigate("/additions/additioncategory/overview");
    }

    if (state.command.cancel.status === "success") {
        navigate("/additions/additioncategory/overview");
    }

    const isLoadingOverlay = state.command.saveAdditionCategory.status === "pending";

    return (<>
        <LoadingOverlayContainer>
            {isLoadingOverlay ? <LoadingOverlayComponent /> : <></>}
            <Grid
                container
                spacing={3}>
                <Grid
                    container
                    item
                    direction="row">
                    <TextBoxComponent name="Übersetzungskey"
                        value={state.actualData.categorykey}
                        onChange={(value) => dispatch(updateCategoryKey(value))}
                        isRequired={true}
                        isAutoFocus={true} />
                    <TextBoxComponent name="Kategorie Text"
                        value={state.actualData.categoryvalue}
                        onChange={(value) => dispatch(updateCategoryText(value))}
                        isRequired={true}
                        isAutoFocus={true} />
                </Grid>
                <CalingaLinkComponent projectName={getConfig().calinga.project.additionCategory}/>
                <EditorButtonComponent
                    canExecute={state.command.saveAdditionCategory.canExecute}
                    save={() => dispatch(saveAdditionCategory())}
                    cancelSave={() => dispatch(cancelSave())}
                />
            </Grid>
        </LoadingOverlayContainer>
    </>)
};