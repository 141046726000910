import { doesExist } from "services/validation.service"
import { ProductionInstructionEditState } from "./production_instruction_edit.model"

export const updateCanSave = (state: ProductionInstructionEditState) => {
    state.command.editProductionInstruction.canExecute =
        doesExist(state.actualData.productionInstructionEditor.isFreeTextEnabled)
        && doesExist(state.actualData.productionInstructionEditor.isDocumentsEnabled)
        && doesExist(state.actualData.productionInstructionEditor.isAutomaticProductionEnabled)
        && doesExist(state.actualData.productionInstructionEditor.calingaKey)
        && doesExist(state.actualData.productionInstructionEditor.symbol);
}