import { Grid } from '@mui/material';
import { DropdownComponent } from 'shared/components/form_controls/dropdown/dropdown.component';
import { SelectionArticleTypeComponent } from './addition_validity_item_articleType_selection.component';
import { SelectionQualityComponent } from './addition_validity_item_quality_selection.component';
import { isNullOrWhitespace } from 'services/validation.service';
import { IAdditionEditor, IAdditionEditorAction, IAdditionInitial, IBodyAreaViewModel, IProductLineViewModel } from 'models/additions/addition_editor.model';

interface ValidityItemComponentProps {
    additionInital: IAdditionInitial,
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction,
}

export const ValidityItemComponent: React.FC<ValidityItemComponentProps> = (
    {
        additionInital,
        additionEditor,
        additionEditorActions,
    }) => {

    const calculateMainProductLines = () => {
        return additionInital.mappedBaseData.availableMainProductLines.map(x =>
        ({
            id: x.id,
            name: x.name
        } as IProductLineViewModel)
        )
    }

    const calculateBodyAreas = () => {
        const selectedMainProductLine = additionInital.mappedBaseData.availableMainProductLines
            .find(x => x.id === additionEditor.addition.editableScope.mainProductLine.id);

        if(!selectedMainProductLine)
        {
            return []
        }

        return selectedMainProductLine.bodyAreas.map(x =>
        ({
            id: x.id,
            name: x.name
        } as IBodyAreaViewModel)
        )
    }

    const bodyAreaSelectionReadOnly = isNullOrWhitespace(additionEditor.addition.editableScope.mainProductLine.name);

    return (
        <Grid spacing={3}
            direction="column"
            container
            marginBottom={"20px"}
            justifyContent="space-between">
            <Grid item
                container
                direction="row">
                <Grid item>
                    <DropdownComponent
                        name="Hauptproduktlinie"
                        data={calculateMainProductLines()}
                        isLoading={false}
                        isRequired={true}
                        onSelect={additionEditorActions.updateMainProductLine}
                        displayedProperties={["name"]}
                        selectedValue={additionEditor.addition.editableScope.mainProductLine}
                    />
                </Grid>
                <Grid item>
                    <DropdownComponent
                        name="Körperregion"
                        data={bodyAreaSelectionReadOnly ? [] : calculateBodyAreas()}
                        isLoading={false}
                        isRequired={true}
                        isReadonly={bodyAreaSelectionReadOnly}
                        onSelect={additionEditorActions.updateBodyArea}
                        displayedProperties={["name"]}
                        selectedValue={additionEditor.addition.editableScope.bodyArea}
                    />
                </Grid>
            </Grid>
            <Grid container
                spacing={3}
                item
                md={12}>
                <SelectionArticleTypeComponent
                    onFilterSelected={additionEditorActions.filterSelectedArticleType}
                    onFilterUnselected={additionEditorActions.filterUnselectedArticleType}
                    select={additionEditorActions.selectArticleTypes}
                    unselect={additionEditorActions.unselectArticleTypes}
                    allow={additionEditorActions.allowArticleType}
                    deny={additionEditorActions.denyArticleType}
                    showSelectedAllIcon={true}
                    isReadOnly={isNullOrWhitespace(additionEditor.addition.editableScope.bodyArea.name)}
                    articleTypeSelection={additionEditor.addition.editableScope.articleTypes} />
            </Grid>
            <Grid container
                spacing={3}
                item
                md={12}>
                <SelectionQualityComponent
                    onFilterSelected={additionEditorActions.filterSelectedQuality}
                    onFilterUnselected={additionEditorActions.filterUnselectedQuality}
                    select={additionEditorActions.selectQualities}
                    showSelectedAllIcon={true}
                    unselect={additionEditorActions.unselectQualities}
                    allow={additionEditorActions.allowQuality}
                    deny={additionEditorActions.denyQuality}
                    isReadOnly={isNullOrWhitespace(additionEditor.addition.editableScope.bodyArea.name)}
                    qualitySelection={additionEditor.addition.editableScope.qualities} />
            </Grid>
        </Grid>
    );
};