import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { saveProductionInstruction, getProductionInstructionCalingaKeys, cancelSave } from './production_instruction_create.thunks';
import { ProductionInstructionCreateState } from './production_instruction_create.model';
import { IProductionInstructionCalingaKey } from 'models/production_instructions/production_instruction_calinga_key';
import { updateCanSave } from './production_instruction_create.reducer';
import { YesNoOptions } from 'models/boolean_radio_box.options';
import { EnumDescriptor } from 'models/enum_descriptor';

const initialState: ProductionInstructionCreateState = {
    loadedData: {
        calingaKeys: [],
        isDocumentsEnabled: YesNoOptions,
        isFreeTextEnabled: YesNoOptions,
        isAutomaticProductionEnabled: YesNoOptions,
    }, actualData: {
        productionInstructionEditor: {
            symbol: '',
            isDocumentsEnabled: YesNoOptions.find(x => x.value === false),
            isFreeTextEnabled: YesNoOptions.find(x => x.value === false),
            isAutomaticProductionEnabled: YesNoOptions.find(x => x.value === false),
            calingaKey: null
        },
    }, query: {
        initData: { status: "idle", canExecute: true },
        getProductionInstructionCalingaKeys: { status: "idle", canExecute: true },
    }, command: {
        saveProductionInstruction: { status: "idle", canExecute: false }, cancel: { status: "idle", canExecute: false },
    }
}

export const productionInstructionCreateSlice = createSlice({
    name: 'production-instruction/create', initialState, reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        }, saveProductionInstructionErrorCompleted: (state) => {
            state.command.saveProductionInstruction.status = "idle";
        }, saveProductionInstructionCompleted: (state) => {
            state.command.saveProductionInstruction.status = "idle";
        }, selectSymbol: (state, action: PayloadAction<string>) => {
            state.actualData.productionInstructionEditor.symbol = action.payload;
            updateCanSave(state);
        }, selectIsDocumentsEnabled: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.productionInstructionEditor.isDocumentsEnabled = action.payload;
            updateCanSave(state);
        }, selectIsFreetextEnabled: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.productionInstructionEditor.isFreeTextEnabled = action.payload;
            updateCanSave(state);
        }, selectIsAutoProductionEnabled: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.productionInstructionEditor.isAutomaticProductionEnabled = action.payload;
            updateCanSave(state);
        }, selectCalingaKey: (state, action: PayloadAction<IProductionInstructionCalingaKey>) => {
            state.actualData.productionInstructionEditor.calingaKey = action.payload;
            updateCanSave(state);
        },
    }, extraReducers: (builder) => {
        builder.addCase(saveProductionInstruction.pending, (state, action) => {
            state.command.saveProductionInstruction.status = 'pending'
            state.command.saveProductionInstruction.canExecute = false;
        }).addCase(saveProductionInstruction.rejected, (state, action) => {
            state.command.saveProductionInstruction.status = "error"
            state.command.saveProductionInstruction.canExecute = true;
            state.command.saveProductionInstruction.message = action.error.message;
        }).addCase(saveProductionInstruction.fulfilled, (state, action) => {
            state.command.saveProductionInstruction.status = "success"
            state.command.saveProductionInstruction.canExecute = true;
        }).addCase(getProductionInstructionCalingaKeys.pending, (state, action) => {
            state.query.getProductionInstructionCalingaKeys.status = 'pending'
            state.query.getProductionInstructionCalingaKeys.canExecute = false;
        }).addCase(getProductionInstructionCalingaKeys.rejected, (state, action) => {
            state.query.getProductionInstructionCalingaKeys.status = "error"
            state.query.getProductionInstructionCalingaKeys.canExecute = true;
        }).addCase(getProductionInstructionCalingaKeys.fulfilled, (state, action) => {
            state.query.getProductionInstructionCalingaKeys.status = "success"
            state.query.getProductionInstructionCalingaKeys.canExecute = true;
            const keys = action.payload.getData();
            state.loadedData.calingaKeys = keys;

            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancel.status = 'pending'
            state.command.cancel.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancel.status = "success"
            state.command.cancel.canExecute = false;
        })
    }
})

export const {
    resetState,
    saveProductionInstructionErrorCompleted,
    saveProductionInstructionCompleted,
    selectSymbol,
    selectIsDocumentsEnabled,
    selectIsFreetextEnabled,
    selectIsAutoProductionEnabled,
    selectCalingaKey,
} = productionInstructionCreateSlice.actions

export default productionInstructionCreateSlice.reducer