import IAdditionOverview from "models/additions/addition_overview";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import IAvailableFootType from "models/available_basedata/available_foot_type";
import IAvailableQuality from "models/available_basedata/available_quality";
import { ICharacteristicValue } from "models/characteristic_types/characteristic_type";
import IMasspointGroupOverview from "models/masspoint_groups/masspoint_group_overview";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import IMasspointOverview from "models/masspoints/masspoint_overview";

export const getIMasspointGroupOverviewDisplayName = (massPointGroup: IMasspointGroupOverview) => {
    return `${massPointGroup.identifier} - ${massPointGroup.nameTranslation}`;
}

export const getICharacteristicValueDisplayName = (value: ICharacteristicValue): string => {
    return `${value.name}`;
}

export const getIAvailableArticleTypeDisplayName = (articleType: IAvailableArticleType) : string => {
    return `${articleType.erpId} ${articleType.name}`;
}

export const getIAvailableQualityDisplayName = (quality: IAvailableQuality) : string => {
    return `${quality.erpId} ${quality.name}`;
}

export const getIMasspointOverviewDisplayName = (mp: IMasspointOverview) : string => {
    return `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`
}

export const getIAdditionOverviewDisplayName = (addition: IAdditionOverview) : string => {
    return `${addition.option} - ${addition.description}`
}

export const getIAvailableFootTypeDisplayName = (foottype: IAvailableFootType) : string => {
    return `${foottype.externalIdentifier} - ${foottype.name}`
}