import React from "react";
import { AdditionsOverviewComponent } from './overview/overview.component';
import { AdditionCategoriesOverviewComponent } from './addition_categories_overview/additioncategory_overview.component';
import { AdditionOverviewComponent } from './addition_overview/addition_overview.component';
import { AdditionCategoryCreateComponent } from './addition_category_create/addition_category_create.component';
import { ProductionInstructionOverviewComponent } from "./production_instruction/production_instruction_overview/production_instruction_overview.component";
import { ProductionInstructionCreateComponent } from "./production_instruction/production_instruction_create/production_instruction_create.component";
import { Route, Routes } from "react-router-dom";
import { AdditionPositionOverviewComponent } from "./addition_position/addition_position_overview/addition_position_overview.component";
import { AdditionPositionCreateComponent } from "./addition_position/addition_position_create/addition_position_create.component";
import { AdditionPositionEditComponent } from "./addition_position/addition_position_edit/addition_position_edit.component";
import { PositionCategoryOverviewComponent } from "./position_categories/position_categories_overview/position_category_overview.component";
import { PositionCategoryCreateComponent } from "./position_categories/position_category_create/position_category_create.component";
import { PositionCategoryItemDetailComponent } from "./position_categories/position_category_item_details/position_category_item_details.component";
import { PositionCategoryEditComponent } from "./position_categories/position_category_edit/position_category_edit.component";
import { CharacteristicTypeOverviewComponent } from "./characteristic_type/characteristic_type_overview/characteristic_type_overview.component";
import { CharacteristicTypeCreateComponent } from "./characteristic_type/characteristic_type_create/characteristic_type_create.component";
import { CharacteristicTypeEditComponent } from "./characteristic_type/characteristic_type_edit/characteristic_type_edit.component";
import { CharacteristicCategoryOverviewComponent } from "./characteristic_category/characteristic_category_overview/characteristic_category_overview.component";
import { CharacteristicCategoryCreateComponent } from "./characteristic_category/characteristic_category_create/characteristic_category_create.component";
import { CharacteristicCategoryEditComponent } from "./characteristic_category/characteristic_category_edit/characteristic_category_edit.component";
import { ProductionInstructionEditComponent } from "./production_instruction/production_instruction_edit/production_instruction_edit.component";
import { AdditionComponent } from "./addition/addition.component";

export const AdditionsComponent = () => {
    return (<Routes>
        <Route element={<AdditionsOverviewComponent/>} path="/"/>
        <Route element={<AdditionOverviewComponent/>} path="addition/overview"/>
        <Route element={<AdditionComponent/>} path="addition/create"/>
        <Route element={<AdditionComponent/>} path="addition/edit/:additionid"/>
        <Route element={<AdditionCategoriesOverviewComponent/>} path="additioncategory/overview"/>
        <Route element={<AdditionCategoryCreateComponent/>} path="additioncategory/create"/>
        <Route element={<ProductionInstructionOverviewComponent/>} path="production-instruction/overview"/>
        <Route element={<ProductionInstructionCreateComponent/>} path="production-instruction/create"/>
        <Route element={<ProductionInstructionEditComponent/>} path="production-instruction/edit/:id"/>
        <Route element={<AdditionPositionOverviewComponent/>} path="/additionposition"/>
        <Route element={<AdditionPositionCreateComponent/>} path="/additionposition/create"/>
        <Route element={<AdditionPositionEditComponent/>} path="/additionposition/edit/:id"/>
        <Route element={<PositionCategoryOverviewComponent/>} path="/positioncategory/overview"/>
        <Route element={<PositionCategoryItemDetailComponent/>}
               path="/positioncategory/positioncategorydetails/:positionCategoryId"/>
        <Route element={<PositionCategoryCreateComponent/>} path="/positioncategory/create"/>
        <Route element={<PositionCategoryEditComponent/>} path="/positioncategory/edit/:positionCategoryId"/>
        <Route element={<CharacteristicTypeOverviewComponent/>} path="/characteristictype/overview"/>
        <Route element={<CharacteristicTypeCreateComponent/>} path="/characteristictype/create"/>
        <Route element={<CharacteristicTypeEditComponent/>} path="/characteristictype/edit/:id"/>
        <Route element={<CharacteristicCategoryOverviewComponent/>} path="/characteristiccategory/overview"/>
        <Route element={<CharacteristicCategoryCreateComponent/>} path="/characteristiccategory/create"/>
        <Route element={<CharacteristicCategoryEditComponent/>} path="/characteristiccategory/edit/:id"/>
    </Routes>)
}