import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductionInstructionEditState } from "./production_instruction_edit.model";
import { YesNoOptions } from "../../../../models/boolean_radio_box.options";
import { EnumDescriptor } from "../../../../models/enum_descriptor";
import { IProductionInstructionCalingaKey } from "../../../../models/production_instructions/production_instruction_calinga_key";
import { updateCanSave } from "./production_instruction_edit.reducer";
import { getProductionInstructionCalingaKeys, editProductionInstruction, loadInitialData } from "./production_instruction_edit.thunks";


const initialState: ProductionInstructionEditState = {
    loadedData: {
        calingaKeys: [],
    },
    actualData: {
        id: 0,
        productionInstructionEditor: {
            symbol: '',
            isDocumentsEnabled: YesNoOptions.find(x => x.value === false),
            isFreeTextEnabled: YesNoOptions.find(x => x.value === false),
            isAutomaticProductionEnabled: YesNoOptions.find(x => x.value === false),
            calingaKey: null,
        },
    },
    query: {
        initData: {
            status: "idle",
            canExecute: true
        },
        getProductionInstructionCalingaKeys: {
            status: "idle",
            canExecute: false
        },
    },
    command: {
        editProductionInstruction: {
            status: "idle",
            canExecute: false
        },
    }

}

export const productionInstructionEditSlice = createSlice({
    name: 'production-instruction/edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        saveProductionInstructionErrorCompleted: (state) => {
            state.command.editProductionInstruction.status = "idle";
        },
        saveProductionInstructionCompleted: (state) => {
            state.command.editProductionInstruction.status = "idle";
        },
        selectSymbol: (state, action: PayloadAction<string>) => {
            state.actualData.productionInstructionEditor.symbol = action.payload;
            updateCanSave(state);
        },
        selectIsDocumentsEnabled: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.productionInstructionEditor.isDocumentsEnabled = action.payload;
            updateCanSave(state);
        },
        selectIsFreetextEnabled: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.productionInstructionEditor.isFreeTextEnabled = action.payload;
            updateCanSave(state);
        },
        selectIsAutoProductionEnabled: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.productionInstructionEditor.isAutomaticProductionEnabled = action.payload;
            updateCanSave(state);
        },
        selectCalingaKey: (state, action: PayloadAction<IProductionInstructionCalingaKey>) => {
            state.actualData.productionInstructionEditor.calingaKey = action.payload;
            updateCanSave(state);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(editProductionInstruction.pending, (state, action) => {
            state.command.editProductionInstruction.status = 'pending'
            state.command.editProductionInstruction.canExecute = false;
        }).addCase(editProductionInstruction.rejected, (state, action) => {
            state.command.editProductionInstruction.status = "error"
            state.command.editProductionInstruction.canExecute = true;
            state.command.editProductionInstruction.message = action.error.message;
        }).addCase(editProductionInstruction.fulfilled, (state, action) => {
            state.command.editProductionInstruction.status = "success"
            state.command.editProductionInstruction.canExecute = true;
        }).addCase(loadInitialData.pending, (state, action) => {
            state.query.initData.status = 'pending'
            state.query.initData.canExecute = false;
        }).addCase(loadInitialData.rejected, (state, action) => {
            state.query.initData.status = 'error'
            state.query.initData.canExecute = false;
        }).addCase(loadInitialData.fulfilled, (state, action) => {
            state.query.initData.status = 'success'
            state.query.initData.canExecute = false;
            const prodInstruction = action.payload.getData();
            state.actualData.productionInstructionEditor.symbol = prodInstruction.symbol;
            state.actualData.productionInstructionEditor.calingaKey = state.loadedData.calingaKeys.find(x => x.key === prodInstruction.calingaKey);
            state.actualData.productionInstructionEditor.isAutomaticProductionEnabled = YesNoOptions.find(x => x.value === prodInstruction.isAutomaticProductionEnabled);
            state.actualData.productionInstructionEditor.isFreeTextEnabled = YesNoOptions.find(x => x.value === prodInstruction.isFreeTextEnabled);
            state.actualData.productionInstructionEditor.isDocumentsEnabled = YesNoOptions.find(x => x.value === prodInstruction.isDocumentsEnabled);
            state.actualData.id = prodInstruction.productionInstructionId;
        }).addCase(getProductionInstructionCalingaKeys.pending, (state, action) => {
            state.query.getProductionInstructionCalingaKeys.status = 'pending'
            state.query.getProductionInstructionCalingaKeys.canExecute = false;
        }).addCase(getProductionInstructionCalingaKeys.rejected, (state, action) => {
            state.query.getProductionInstructionCalingaKeys.status = "error"
            state.query.getProductionInstructionCalingaKeys.canExecute = true;
        }).addCase(getProductionInstructionCalingaKeys.fulfilled, (state, action) => {
            state.query.getProductionInstructionCalingaKeys.status = "success"
            state.query.getProductionInstructionCalingaKeys.canExecute = true;
            const keys = action.payload.getData();
            state.loadedData.calingaKeys = keys;
        })
    }
})

export const {
    resetState,
    saveProductionInstructionErrorCompleted,
    saveProductionInstructionCompleted,
    selectSymbol,
    selectIsDocumentsEnabled,
    selectIsFreetextEnabled,
    selectIsAutoProductionEnabled,
    selectCalingaKey,
} = productionInstructionEditSlice.actions


export default productionInstructionEditSlice.reducer