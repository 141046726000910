import React, { useEffect } from "react";
import { LoadingOverlayComponent, LoadingOverlayContainer } from '../../../shared/components/loading_overlay/loading_overlay.component';
import {
    resetState,
    saveProductionInstructionErrorCompleted,
    saveProductionInstructionCompleted,
    selectSymbol,
    selectIsDocumentsEnabled,
    selectIsFreetextEnabled,
    selectCalingaKey, selectIsAutoProductionEnabled,
} from './redux/production_instruction_create.slice';
import { useSnackbar } from "notistack";
import { cancelSave, getProductionInstructionCalingaKeys, saveProductionInstruction } from "./redux/production_instruction_create.thunks";
import { useNavigate } from "react-router-dom";
import { ProductionInstructionEditorComponent } from "../components/production_instruction_editor.component";
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import { AppDispatch } from "../../../app/redux_store";
import { IProductionInstructionEditorAction } from "../components/redux/production_instruction_editor.model";

export const ProductionInstructionCreateComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.production_instruction_create);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getProductionInstructionCalingaKeys());
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.getProductionInstructionCalingaKeys.status === "error") {
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.command.saveProductionInstruction.status === "error") {
        enqueueSnackbar(state.command.saveProductionInstruction.message, { variant: "error" });
        dispatch(saveProductionInstructionErrorCompleted());
    }

    if (state.command.saveProductionInstruction.status === "warning") {
        enqueueSnackbar(state.command.saveProductionInstruction.message, { variant: "warning" });
        dispatch(saveProductionInstructionErrorCompleted());
    }

    if (state.command.saveProductionInstruction.status === "success") {
        enqueueSnackbar("Produktionsanweisung gespeichert", { variant: "success" });
        dispatch(saveProductionInstructionCompleted());
        navigate("/additions/production-instruction/overview");
    }

    if (state.command.cancel.status === "success") {
        navigate("/additions/production-instruction/overview");
    }

    const isLoadingOverlay = state.command.saveProductionInstruction.status === "pending";

    const actions: IProductionInstructionEditorAction = {
        saveProductionInstruction: () => dispatch(saveProductionInstruction()),
        cancelSave: () => dispatch(cancelSave()),
        selectProductionInstructionCalingaKey: (value) => dispatch(selectCalingaKey(value)),
        selectSymbol: (value) => dispatch(selectSymbol(value)),
        selectIsDocumentEnabled: (value) => dispatch(selectIsDocumentsEnabled(value)),
        selectIsFreetextEnabled: (value) => dispatch(selectIsFreetextEnabled(value)),
        selectIsAutoProductionEnabled: (value) => dispatch(selectIsAutoProductionEnabled(value))
    };

    return (<>
        <LoadingOverlayContainer>
            {isLoadingOverlay ? <LoadingOverlayComponent/> : <></>}
            <ProductionInstructionEditorComponent
                canSave={state.command.saveProductionInstruction.canExecute}
                productionInstructionEditor={state.actualData.productionInstructionEditor}
                calingaKeys={state.loadedData.calingaKeys}
                productionInstructionEditorActions={actions}
                editMode={false}
            />
        </LoadingOverlayContainer>
    </>)
}