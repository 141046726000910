import { ArticleTypeFilterList, QualityFilterList, IArticleTypeSelectionViewModel, IAdditionViewModelScope, IAdditionEditor, IAdditionInitial } from "models/additions/addition_editor.model";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import { removeDuplicates } from "shared/helpers/removeDuplicates";
import { filterArticleTypes, filterQualities } from "./filters";
import { doesExist } from "services/validation.service";
import IAvailableQuality from "models/available_basedata/available_quality";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import { LockTypeEnum } from "shared/components/selectionComponent/models/locktype.enum";

export const moveArticleTypeFromUnselectedToSelected = (additionEditor: IAdditionEditor, additionInitial: IAdditionInitial, selection: IAvailableArticleType[]) => {
    const editableScope = additionEditor.addition.editableScope;
    const selectedList = editableScope.articleTypes.selectedList;
    const unselectedList = editableScope.articleTypes.unSelectedList;

    selection.forEach(item => {
        moveArticleTypeFromSourceToDestination(unselectedList, selectedList, item);
    });

    editableScope.qualities = calculateQualitiesSelection(additionEditor.addition.editableScope, additionInitial);
    filterArticleTypes(selectedList);
    filterArticleTypes(unselectedList);
}

export const moveArticleTypeFromSelectedToUnselected = (additionEditor: IAdditionEditor, additionInitial: IAdditionInitial, selection: IAvailableArticleType[]) => {
    const editableScope = additionEditor.addition.editableScope;
    const selectedList = editableScope.articleTypes.selectedList;
    const unselectedList = editableScope.articleTypes.unSelectedList;

    selection.forEach(item => {
        moveArticleTypeFromSourceToDestination(selectedList, unselectedList, item);
    });

    editableScope.qualities = calculateQualitiesSelection(additionEditor.addition.editableScope, additionInitial);
    filterArticleTypes(selectedList);
    filterArticleTypes(unselectedList);
}

export const moveQualityFromUnselectedToSelected = (additionEditor: IAdditionEditor, selection: IAvailableQuality[]) => {
    const editableScope = additionEditor.addition.editableScope;
    const selectedList = editableScope.qualities.selectedList;
    const unselectedList = editableScope.qualities.unSelectedList;

    selection.forEach(item => {
        moveQualitiesFromSourceToDestination(unselectedList, selectedList, item);
    });

    filterQualities(selectedList);
    filterQualities(unselectedList);
}

export const moveQualityFromSelectedToUnselected = (additionEditor: IAdditionEditor, selection: IAvailableQuality[]) => {
    const editableScope = additionEditor.addition.editableScope;
    const selectedList = editableScope.qualities.selectedList;
    const unselectedList = editableScope.qualities.unSelectedList;

    selection.forEach(item => {
        moveQualitiesFromSourceToDestination(selectedList, unselectedList, item);
    });

    filterQualities(selectedList);
    filterQualities(unselectedList)
}

export const moveArticleTypeFromSourceToDestination = (source: ArticleTypeFilterList,
    destination: ArticleTypeFilterList, articleType: IAvailableArticleType) => {
    const indexInAll = source.allItems.findIndex(x => x.id === articleType.id);
    destination.allItems.push(articleType);
    source.allItems.splice(indexInAll, 1);
}

export const moveQualitiesFromSourceToDestination = (source: QualityFilterList, destination: QualityFilterList,
    quality: IAvailableQuality) => {
    const indexInAll = source.allItems.findIndex(x => x.id === quality.id);
    destination.allItems.push(quality);
    source.allItems.splice(indexInAll, 1);
}

export const calculateSelectableArticleTypesByScope = (scope: IAdditionViewModelScope, additionInitial: IAdditionInitial) => {
    const selectedMainProductLine = scope.mainProductLine;
    const selectedBodyArea = scope.bodyArea;

    const selectedMainProductLineMapped = additionInitial.mappedBaseData.availableMainProductLines.find(x => x.id === selectedMainProductLine.id);
    const selectedBodyAreaMapped = selectedMainProductLineMapped.bodyAreas.find(x => x.id === selectedBodyArea.id);

    return selectedBodyAreaMapped ? selectedBodyAreaMapped.articleTypes : [];
}

export const calculateSelectableQualitiesByScope = (scope: IAdditionViewModelScope, additionInitial: IAdditionInitial) => {
    const selectedMainProductLine = scope.mainProductLine;
    const selectedBodyArea = scope.bodyArea;
    const selectedArticleTypes = scope.articleTypes;

    const selectedMainProductLineMapped = additionInitial.mappedBaseData.availableMainProductLines.find(x => x.id === selectedMainProductLine.id);
    const selectedBodyAreaMapped = selectedMainProductLineMapped.bodyAreas.find(x => x.id === selectedBodyArea.id);
    if(!selectedBodyAreaMapped){
        return [];
    }
    const selectedArticleTypesMapped = calculateSelectedArticleTypes(selectedBodyAreaMapped, selectedArticleTypes);
    if(!selectedArticleTypesMapped){
        return [];
    }
    const selectableQualities = selectedArticleTypesMapped.map(x => x.qualities).flat();
    
    return removeDuplicates(selectableQualities, "id");
}

export const calculateSelectedArticleTypes = (bodyArea: IAvailableBodyArea, articleTypeSelection: IArticleTypeSelectionViewModel) => {
    if(articleTypeSelection.lockType === LockTypeEnum.Allow){ 
        if(articleTypeSelection.selectedList.allItems.length === 0){
            return bodyArea.articleTypes.filter(x=> articleTypeSelection.unSelectedList.allItems.some(y=> x.id === y.id) );
        }
        else{
            return bodyArea.articleTypes.filter(x=> articleTypeSelection.selectedList.allItems.some(y=> x.id === y.id) );
        }
    }
    else{
        if(articleTypeSelection.selectedList.allItems.length === 0){
            return [];
        }
        return bodyArea.articleTypes.filter(x=> articleTypeSelection.unSelectedList.allItems.some(y=> x.id === y.id) ); 
    }
}

export const calculateArticleTypeSelection = (scope: IAdditionViewModelScope, additionInitial: IAdditionInitial) => {
    const selectableArticleTypes = calculateSelectableArticleTypesByScope(scope, additionInitial);
    const selectedArticleTypes = scope.articleTypes.selectedList.allItems;
    
    const newArticleTypeSelection = {
        lockType: scope.articleTypes.lockType,
        selectedList: {
            searchText: scope.articleTypes.selectedList.searchText,
            allItems: selectableArticleTypes.filter(x => selectedArticleTypes.some(y=> y.id === x.id)),
            filteredItems: selectableArticleTypes.filter(x => selectedArticleTypes.some(y=> y.id === x.id)),
        },
        unSelectedList: {
            searchText: scope.articleTypes.unSelectedList.searchText,
            allItems: selectableArticleTypes.filter(x => selectedArticleTypes.every(y=> y.id !== x.id)),
            filteredItems: selectableArticleTypes.filter(x => selectedArticleTypes.every(y=> y.id !== x.id)),
        },
    }
    
    filterArticleTypes(newArticleTypeSelection.selectedList);
    filterArticleTypes(newArticleTypeSelection.unSelectedList);

    return newArticleTypeSelection;
}

export const calculateQualitiesSelection = (scope: IAdditionViewModelScope, additionInitial: IAdditionInitial) => {
    const selectableQualities = calculateSelectableQualitiesByScope(scope, additionInitial);
    const selectedQualities = scope.qualities.selectedList.allItems;

    const newQualitySelection = {
        lockType: scope.qualities.lockType,
        selectedList: {
            searchText: scope.qualities.selectedList.searchText,
            allItems: selectableQualities.filter(x => selectedQualities.some(y=> y.id === x.id)),
            filteredItems: selectableQualities.filter(x => selectedQualities.some(y=> y.id === x.id)),
        },
        unSelectedList: {
            searchText: scope.qualities.unSelectedList.searchText,
            allItems: selectableQualities.filter(x => selectedQualities.every(y=> y.id !== x.id)),
            filteredItems: selectableQualities.filter(x => selectedQualities.every(y=> y.id !== x.id)),
        },
    }

    filterQualities(newQualitySelection.selectedList)
    filterQualities(newQualitySelection.unSelectedList)

    return newQualitySelection;
}

export const calculateNewValidityScope = (additionInitial: IAdditionInitial): IAdditionViewModelScope => {
    return {
        mainProductLine: additionInitial.mainProductLines[0],
        bodyArea: additionInitial.bodyAreas[0],
        articleTypes: {
            lockType: LockTypeEnum.Allow,
            selectedList: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            unSelectedList: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
        },
        qualities: {
            lockType: LockTypeEnum.Allow,
            selectedList: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            unSelectedList: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
        },
        additionId: null,
    };
}

export const createEditValidityScope = (configuration: IAdditionViewModelScope): IAdditionViewModelScope => {

    const editorConfiguration: IAdditionViewModelScope = {
        mainProductLine: configuration.mainProductLine,
        bodyArea: configuration.bodyArea,
        articleTypes: configuration.articleTypes,
        qualities: configuration.qualities
    };

    return editorConfiguration;
}

export const calculateDependentFields = (additionEditor: IAdditionEditor, additionInitial: IAdditionInitial) => {
    const editableScope = additionEditor.addition.editableScope;
    const selectedBodyArea = editableScope.bodyArea;
    if(selectedBodyArea.id){
        const loadedMainProductLine = additionInitial.mappedBaseData.availableMainProductLines.find(x=> x.id === editableScope.mainProductLine.id);
        const selectedBodyAreaNoLongerFits = !loadedMainProductLine.bodyAreas.some(x=> x.id === selectedBodyArea.id);
        if(selectedBodyAreaNoLongerFits){
            editableScope.bodyArea = {id: null, name: ""};
        }
        else{
            editableScope.articleTypes = calculateArticleTypeSelection(additionEditor.addition.editableScope, additionInitial);
            editableScope.qualities = calculateQualitiesSelection(additionEditor.addition.editableScope, additionInitial);
        }
    }
}

export const dependentPlaceholdersStillValid = (additionEditor: IAdditionEditor) => {
    const editableScope = additionEditor.addition.editableScope;
    const editableScopeIndex = additionEditor.addition.editableScopeIndex;
    const scopeDeltingIndex = additionEditor.addition.scopeDeletingIndex;
    const otherAdditionScopes = additionEditor.addition.additionScopes.filter((_,index)=> editableScopeIndex !== index && scopeDeltingIndex !== index); 

    const usedQualitiesIds = additionEditor.addition.placeholder.qualityConfigs.filter(x=> doesExist(x.quality)).map(qC => qC.quality.id);
    const availableQualities = [...getSelectedQualitiesFromScopes(otherAdditionScopes), ...getSelectedQualitiesFromScopes(editableScope ? [editableScope] : [])];
    
    const result = usedQualitiesIds.every(x => availableQualities.some(y => y.id === x));

    return result;
}

export const getSelectedQualitiesFromScopes = (additionScopes: IAdditionViewModelScope[]) => {
    let selectableQualities: IAvailableQuality[] = []
    additionScopes.forEach(scope => {
        let scopeQualities: IAvailableQuality[] = []
        if (scope.qualities.lockType === LockTypeEnum.Allow) {
            if (scope.qualities.selectedList.allItems.length > 0) {
                scopeQualities = scope.qualities.selectedList.allItems;
            }
            else {
                scopeQualities = scope.qualities.unSelectedList.allItems;
            }
        }
        else {
            if (scope.qualities.selectedList.allItems.length > 0) {
                scopeQualities = scope.qualities.unSelectedList.allItems;
            }
            else {
                scopeQualities = [];
            }
        }
        selectableQualities.push(...scopeQualities);
    })

    return removeDuplicates(selectableQualities, "id");

}

export const isEditableValidityScopeValid = (additionEditor: IAdditionEditor, additionInitial: IAdditionInitial) => {
    const editableScope = additionEditor.addition.editableScope;

    const mainProductLine = additionInitial.mappedBaseData.availableMainProductLines.find(x => x.id === editableScope.mainProductLine.id);
    const bodyArea = mainProductLine?.bodyAreas.find(x=> x.id === editableScope.bodyArea.id)

    if(bodyArea){
        return true;
    }
    else{
        return false
    }
}

export const hasValidValidityScopes = (additionEditor: IAdditionEditor, additionInitial: IAdditionInitial) => {
    const editableScopeIsClosed = !additionEditor.addition.editableScope;
    
    return editableScopeIsClosed && 
    isUniqueValidityScope(additionEditor.addition.additionScopes) && 
    validityScopeValuesValid(additionEditor,additionInitial);
}

export const validityScopeValuesValid = (additionEditor: IAdditionEditor, additionInitial: IAdditionInitial) => {
    return !additionEditor.addition.additionScopes.some(validityScope => 
        {
            return !isValidValidityScope(validityScope, additionInitial);
        });
}

export const isValidValidityScopeByIndex = (scopeIndex: number, additionEditor: IAdditionEditor, additionInitial: IAdditionInitial) => {
    const scope = additionEditor.addition.additionScopes[scopeIndex];
    if(scope){
        return isValidValidityScope(scope, additionInitial);
    }
    else{
        return false;
    }
}

const isValidValidityScope = (validityScope: IAdditionViewModelScope, additionInitial: IAdditionInitial) => {
    const mainProductLine = additionInitial.mappedBaseData.availableMainProductLines.find(x => x.id === validityScope.mainProductLine.id);
    const bodyArea = mainProductLine?.bodyAreas.find(x=> x.id === validityScope.bodyArea.id);
    const selectableArticleTypes = calculateSelectableArticleTypesByScope(validityScope, additionInitial);
    const selectableQualities = calculateSelectableQualitiesByScope(validityScope, additionInitial);
    const currentSelectableArticleTypes = [...validityScope.articleTypes.selectedList.allItems, ...validityScope.articleTypes.unSelectedList.allItems];
    const currentSelectableQualities = [...validityScope.qualities.selectedList.allItems, ...validityScope.qualities.unSelectedList.allItems];
    const articleTypesValid = currentSelectableArticleTypes.every(x => selectableArticleTypes.some(y=> y.id === x.id));
    const qualitiesValid =currentSelectableQualities.every(x => selectableQualities.some(y=> y.id === x.id));
    
    return doesExist(bodyArea) && articleTypesValid && qualitiesValid;
}

const isUniqueValidityScope = (scopes: IAdditionViewModelScope[]) => {
    const uniqueArray = removeDuplicates(scopes.map(scope => ({ids: `${scope.mainProductLine.id}${scope.bodyArea.id}`})), "ids");
    return uniqueArray.length === scopes.length;
}